import { Pagination, PaginationItem, Stack } from '@mui/material';
import { RoutesVars } from 'const/constRoutes';
import { memo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  count: number;
  page: number | string;
}

const PaginationStack = ({ count, page: currentPage }: Props) => {
  return (
    <Stack spacing={2} display="flex" alignItems="center" p={2}>
      <Pagination
        count={count}
        size="small"
        renderItem={({ page, ...props }) => (
          <PaginationItem
            component={Link}
            to={`/${RoutesVars.ALL_COMPARISONS}?page=${page}`}
            {...props}
            page={page}
            selected={Number(currentPage) === page}
          />
        )}
      />
    </Stack>
  );
};

export default memo(PaginationStack);
