import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';

export interface GetComparisonExportFileServiceParams {
  comparisonId: string;
}

const getComparisonExportFileService = async ({
  comparisonId,
}: GetComparisonExportFileServiceParams) => {
  const response = await axiosApi.get<Blob>(routes.GET_COMPARISON_EXPORT_FILE({ comparisonId }), {
    responseType: 'blob',
  });

  return response.data;
};

export { getComparisonExportFileService };
