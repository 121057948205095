import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const allowedDomains = process.env.REACT_APP_ALLOWED_EMAIL_DOMAINS;

const formSchema = z.object({
  email: z
    .string()
    .email()
    .refine(
      (email) =>
        allowedDomains && allowedDomains.split(',').some((domain) => email.endsWith(`@${domain}`))
    ),
});

export type FormSchema = z.infer<typeof formSchema>;

export const useShareModelForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  return {
    register,
    handleSubmit,
    reset,
    isValid,
  };
};
