export enum QueryKeys {
  EXAMPLE = 'example',
  MODELS_DATA = 'modelsData',
  COMPARISON_DATA = 'comparisonData',
  CREATE_COMPARISON_DATA = 'createComparisonData',
  MULTI_METRIC_COMPARISON_DATA = 'multiMetricComparisonData',
  MULTI_METRIC_COMPARISON_TABLE = 'multiMetricComparisonTable',
  SEND_EMAIL = 'sendEmail',
  INCREASE_SHARES = 'increaseShares',
  COST_OPTIMIZATION_SCENARIOS = 'costOptimizationScenarios',
  LLM_QUERY = 'llmQuery',
  GET_COMPARISON_EXPORT_FILE = 'getComparisonExportFile',
}
