import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import ComparisonCard from 'pages/HomePage/components/ComparisonCard';
import { SectionHeading } from 'components/SectionHeading';
import useComparison from 'hooks/useComparison';
import LoadingState from 'components/LoadingState';
import ErrorState from 'components/ErrorState';
import NoResultFoundState from 'components/NoResultFoundState';
import theme from 'themes/theme';
import { RoutesVars } from 'const/constRoutes';
import stringsJSON from 'assets/strings/strings.json';
import { PopularComparison } from 'const/constants';

interface Props {
  handleShareComparison: (id: string) => void;
  searchText?: string;
}
const { homePage, costOptimizationPage } = stringsJSON;
const { COMPARISON_SEARCH_RESULT_LIMIT, DEFAULT_COMPARISON_COUNT } = PopularComparison;

const PopularComparisonSection = ({ searchText = '', handleShareComparison }: Props) => {
  const { comparisonData, isError, isLoading, refetch } = useComparison({
    perPage: searchText ? COMPARISON_SEARCH_RESULT_LIMIT : DEFAULT_COMPARISON_COUNT,
    searchQuery: searchText,
  });

  const isComparePage = useLocation().pathname.startsWith('/compare');

  const headingText = searchText
    ? homePage.popularComparisonSearchTitle
    : homePage.popularComparisonsSectionTitle;
  const noResultsFound = !comparisonData || !comparisonData?.comparisons?.length;

  const renderCards = () => {
    if (isLoading) {
      return <LoadingState testId="homepage-comparison-spinner" />;
    }

    if (isError) {
      return <ErrorState refetch={refetch} />;
    }

    if (noResultsFound) {
      return <NoResultFoundState />;
    }

    return comparisonData.comparisons.map((data) => {
      return (
        <ComparisonCard
          key={data.id}
          comparisonData={data}
          handleShareComparison={handleShareComparison}
        />
      );
    });
  };

  return (
    <Box component="section" sx={{ width: '100%', height: '100%' }}>
      {!isComparePage ? (
        <SectionHeading
          headingText={headingText}
          buttonText={homePage.allComparisonsLinkText}
          link={RoutesVars.ALL_COMPARISONS}
        />
      ) : (
        <Box display="flex">
          <Typography variant="h3" component="h2" sx={{ lineHeight: 1.85 }}>
            {costOptimizationPage.popularComparisonSectionTitle}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,

          '&::-webkit-scrollbar': { display: 'none' },
          [theme.breakpoints.up('md')]: { display: 'block' },
        }}
      >
        {renderCards()}
      </Box>
    </Box>
  );
};

export default PopularComparisonSection;
