import { Box } from '@mui/material';
import { colorsTheme } from 'themes/theme';

interface Props {
  children: React.ReactNode;
}

const MainBodyWrapper = ({ children }: Props) => {
  return (
    <Box
      sx={{
        maxWidth: '1343px',
        overflow: 'hidden',
        width: '100%',
        m: '0 auto',
        bgcolor: colorsTheme.background.color1,
        borderRadius: 4,
      }}
    >
      {children}
    </Box>
  );
};

export default MainBodyWrapper;
