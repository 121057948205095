import { Stack } from '@mui/material';
import useCostOptimizationScenarios from 'hooks/useCostOptimizationScenarios';
import useModels from 'hooks/useModels';
import { PlaygroundFormSchema } from 'pages/CompareCostOptimizationPage/components/CostOptimizationFormWrapper';
import FilterChip from 'pages/CompareMultiMetricsPage/components/FilterChip';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
  onSubmit: (data: PlaygroundFormSchema) => void;
}

const SelectedFiltersSection = ({ onSubmit }: Props) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitted },
  } = useFormContext<PlaygroundFormSchema>();
  const modelsState = useWatch({
    control,
    name: 'modelPicker',
  });
  const scenariosState = useWatch({
    control,
    name: 'scenarioPicker',
  });

  const { modelsData } = useModels({});
  const { costOptimizationScenariosData } = useCostOptimizationScenarios();

  const checkedModelsIdsArray = Object.keys(modelsState).filter((key) => modelsState[key].checked);
  const checkedModels = modelsData?.filter((item) => checkedModelsIdsArray.includes(item.id));

  const checkedScenario = costOptimizationScenariosData?.find((item) => item.id === scenariosState);

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} my={1.75}>
      {checkedScenario && (
        <FilterChip
          isLoading={false}
          isRefetching={false}
          filter={['Scenario', checkedScenario.name]}
          handleDelete={() => {
            setValue('scenarioPicker', '');
            if (isSubmitted) handleSubmit(onSubmit)();
          }}
        />
      )}
      {checkedModels &&
        checkedModels.map((model) => {
          return (
            <FilterChip
              isLoading={false}
              isRefetching={false}
              key={model.id}
              filter={['Model', model.title]}
              handleDelete={() => {
                setValue(`modelPicker.${model.id}`, {
                  checked: false,
                });
                if (isSubmitted) handleSubmit(onSubmit)();
              }}
            />
          );
        })}
    </Stack>
  );
};

export default SelectedFiltersSection;
