import { useCallback, useRef } from 'react';

interface Props {
  func: () => void;
  timer: number;
}

export const useDebounce = ({ func, timer }: Props) => {
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      func();
      debounceTimerRef.current = null;
    }, timer);
  }, [func, timer]);

  return debouncedFunc;
};
