import { Box, Typography } from '@mui/material';
import theme, { colorsTheme } from 'themes/theme';

interface Props {
  isHuman: boolean;
  message: string;
}

const ConversationBubble = ({ isHuman, message }: Props) => (
  <Box
    py={2.8}
    sx={{
      background: isHuman ? colorsTheme.background.optionHover : colorsTheme.background.color1,
      border: `1px solid ${colorsTheme.background.color3}`,
      borderRadius: theme.spacing(2.5),
      borderBottomLeftRadius: isHuman ? theme.spacing(2.5) : 0,
      borderBottomRightRadius: isHuman ? 0 : theme.spacing(2.5),
      p: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      alignSelf: isHuman ? 'flex-end' : 'flex-start',
      width: 'fit-content',
    }}
  >
    <Typography p={0.5}>{message}</Typography>
  </Box>
);

export default ConversationBubble;
