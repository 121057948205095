import { Send } from '@mui/icons-material';
import { IconButton, InputBase, Box, Typography } from '@mui/material';
import GradientBorderWrapper from 'components/GradientBorderWrapper';
import theme, { colorsTheme } from 'themes/theme';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

interface Props {
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const formSchema = z.object({
  search: z
    .string()
    .min(3, {
      message: 'Search must contain at least 3 character(s)',
    })
    .max(999, {
      message: 'Search must contain at most 999 character(s)',
    })
    .or(z.literal('')),
});

type FormSchema = z.infer<typeof formSchema>;

const SearchInput = ({ setSearchText }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      search: '',
    },
  });

  const onSubmit = async (data: FormSchema) => {
    setSearchText(data.search);
  };

  return (
    <Box>
      <GradientBorderWrapper>
        <Box
          component="form"
          display="flex"
          alignItems="center"
          minHeight={40}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputBase
            sx={{ mx: 2, flex: 1, '& ::placeholder': { fontSize: 14 } }}
            placeholder="Search aIQ Muse..."
            inputProps={{ 'aria-label': 'Search' }}
            {...register('search', {
              setValueAs: (value: string) => value.trim(),
              required: true,
            })}
          />
          <IconButton color="primary" sx={{ p: 1 }} aria-label="Search submit" type="submit">
            <Send sx={{ color: colorsTheme.text.neutral }} fontSize="small" />
          </IconButton>
        </Box>
      </GradientBorderWrapper>
      {errors.search && (
        <Typography
          sx={{
            mt: 0.5,
            fontSize: 14,
            color: theme.palette.warning.main,
          }}
        >
          {errors.search.message}
        </Typography>
      )}
    </Box>
  );
};

export default SearchInput;
