import { FC, ReactNode } from 'react';

import AuthContext, { useAuthContext } from './AuthContext';

interface Props {
  children: ReactNode;
}

const AuthContextProvider: FC<Props> = ({ children }) => {
  const authContext = useAuthContext();

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
