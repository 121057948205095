import { useCallback, useContext, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import FilterChip from 'pages/CompareMultiMetricsPage/components/FilterChip';
import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';
import { AppContext } from 'context/AppContext';

export interface ProcessedFilters {
  model: string[];
  theme: string[];
  categories: string[];
  criteria: { [category: string]: string[] };
}

interface Props {
  isLoading: boolean;
  isRefetching: boolean;
  onSubmit: (data: MultimetricSelectedFilters) => void;
}

const SelectedFiltersSection = ({ onSubmit, isLoading, isRefetching }: Props) => {
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitted },
  } = useFormContext<MultimetricSelectedFilters>();
  const {
    state: { application },
  } = useContext(AppContext);

  const modelPicker = watch('modelPicker');
  const themesPicker = watch('themesPicker');
  const categoriesPicker = watch('categoriesPicker');

  const themesPickerKeys = Object.keys(themesPicker).filter((key) => themesPicker[key]);

  const categoriesPickerKeys = Object.keys(categoriesPicker);

  const processedFilters: ProcessedFilters = useMemo(() => {
    const theme = Object.keys(themesPicker).filter((key) => themesPicker[key].checked);
    const themeIndeterminate = Object.keys(themesPicker).filter(
      (key) => themesPicker[key].indeterminate
    );
    const model = Object.keys(modelPicker).filter((key) => modelPicker[key].checked);

    const categories: string[] = [];
    const criteria: { [category: string]: string[] } = {};
    const themesCategories: string[] = [];
    const allCheckedCategories: string[] = Object.entries(categoriesPicker).reduce<string[]>(
      (acc, [categoryId, categoryState]) => {
        if (categoryState.checked) {
          acc.push(categoryId);
        }
        return acc;
      },
      []
    );

    theme.forEach((id) => {
      const foundTheme = application.themes.find(({ id: themeId }) => id === themeId);
      if (foundTheme) {
        const foundThemeCategories = foundTheme.categories.map(({ id }) => id);
        themesCategories.push(...foundThemeCategories);
      }
    });

    const isThemeLoaded = application.themes.every((themeItem) => {
      const themeFound = themeItem.categories.some(({ id }) => allCheckedCategories.includes(id));
      if (themeFound) {
        return (
          theme.some((id) => themeItem.id === id) ||
          themeIndeterminate.some((id) => themeItem.id === id)
        );
      }
      return true;
    });

    if (isThemeLoaded) {
      Object.entries(categoriesPicker).forEach(([categoryId, categoryState]) => {
        const selectedCriteria = Object.keys(categoryState.criteria).filter(
          (criteriaName) => categoryState.criteria[criteriaName]
        );
        if (categoryState.checked || selectedCriteria.length > 0) {
          if (selectedCriteria.length < Object.keys(categoryState.criteria).length) {
            const foundCategory = application.categories.find(
              (category) => category.id === categoryId
            )?.name;
            if (foundCategory) {
              criteria[foundCategory] = selectedCriteria;
            }
          } else {
            if (!themesCategories.some((id) => id === categoryId)) categories.push(categoryId);
          }
        }
      });
    }

    return {
      model,
      theme,
      categories,
      criteria,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelPicker, themesPicker, categoriesPicker, themesPickerKeys, categoriesPickerKeys]);

  const handleDelete = useCallback(
    (filterType: keyof ProcessedFilters, value: string, category?: string) => {
      switch (filterType) {
        case 'model':
          setValue(`modelPicker.${value}.checked`, false);
          break;
        case 'theme':
          setValue(`themesPicker.${value}.checked`, false);
          setValue(`themesPicker.${value}.indeterminate`, false);
          // Uncheck all categories and criteria within this theme
          const theme = application.themes.find((t) => t.id === value);
          if (theme) {
            theme.categories.forEach((cat) => {
              setValue(`categoriesPicker.${cat.id}.checked`, false);
              setValue(`categoriesPicker.${cat.id}.indeterminate`, false);
              cat.criteria.forEach((crit) => {
                setValue(`categoriesPicker.${cat.id}.criteria.${crit.id}`, false);
              });
            });
          }
          break;
        case 'categories':
          setValue(`categoriesPicker.${value}.checked`, false);
          setValue(`categoriesPicker.${value}.indeterminate`, false);
          // Uncheck all criteria within this category
          Object.keys(categoriesPicker[value].criteria).forEach((criteriaId) => {
            setValue(`categoriesPicker.${value}.criteria.${criteriaId}`, false);
          });
          // Update parent theme state
          const parentTheme = application.themes.find((t) =>
            t.categories.some((c) => c.id === value)
          );
          if (parentTheme) {
            const allUnchecked = parentTheme.categories.every(
              (c) => !categoriesPicker[c.id]?.checked
            );
            setValue(`themesPicker.${parentTheme.id}.checked`, false);
            setValue(`themesPicker.${parentTheme.id}.indeterminate`, !allUnchecked);
          }
          break;
        case 'criteria':
          if (category) {
            const categoryId = application.categories.find(({ name }) => category === name)?.id;
            if (categoryId) {
              setValue(`categoriesPicker.${categoryId}.criteria.${value}`, false);
              // Update category state
              const allUnchecked = Object.values(categoriesPicker[categoryId].criteria).every(
                (v) => !v
              );
              setValue(`categoriesPicker.${categoryId}.checked`, false);
              setValue(`categoriesPicker.${categoryId}.indeterminate`, !allUnchecked);
              // Update parent theme state
              const parentTheme = application.themes.find((t) =>
                t.categories.some((c) => c.id === categoryId)
              );
              if (parentTheme) {
                const allCategoriesUnchecked = parentTheme.categories.every(
                  (c) => !categoriesPicker[c.id]?.checked && !categoriesPicker[c.id]?.indeterminate
                );
                setValue(`themesPicker.${parentTheme.id}.checked`, false);
                setValue(`themesPicker.${parentTheme.id}.indeterminate`, !allCategoriesUnchecked);
              }
            }
          }
          break;
      }
      if (isSubmitted) handleSubmit(onSubmit)();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, handleSubmit, onSubmit, categoriesPicker, application.themes, application.categories]
  );

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} my={1.75}>
      {(Object.keys(processedFilters) as Array<keyof ProcessedFilters>).map((filterType) => {
        if (filterType === 'theme') {
          return processedFilters[filterType].map((value) => {
            return application.themes
              .filter((theme) => theme.id === value)
              .map(({ name: filterName }) => {
                return (
                  <FilterChip
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    key={`${filterType}-${filterName}`}
                    filter={[filterType, filterName]}
                    handleDelete={() => handleDelete(filterType, value)}
                  />
                );
              });
          });
        }
        if (filterType === 'categories') {
          return processedFilters[filterType].map((filterName) => {
            return application.categories
              .filter((categories) => categories.id === filterName)
              .map(({ name: filterName, id }) => {
                return (
                  <FilterChip
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    key={`${filterType}-${filterName}`}
                    filter={[filterType, filterName]}
                    handleDelete={() => handleDelete(filterType, id)}
                  />
                );
              });
          });
        }
        if (filterType === 'criteria') {
          return Object.entries(processedFilters.criteria).map(([category, criteriaList]) =>
            criteriaList.map((criteria) => {
              return application.criteria
                .filter(({ id }) => id === criteria)
                .map(({ name, id }) => (
                  <FilterChip
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    key={`${category}-${name}`}
                    filter={[filterType, name]}
                    handleDelete={() => handleDelete('criteria', id, category)}
                  />
                ));
            })
          );
        }
        return processedFilters[filterType]?.map((filterId) => {
          return application.models
            .filter(({ id }) => id === filterId)
            .map(({ title, id }) => (
              <FilterChip
                isLoading={isLoading}
                isRefetching={isRefetching}
                key={`${filterType}-${title}`}
                filter={[filterType, title]}
                handleDelete={() => handleDelete(filterType, id)}
              />
            ));
        });
      })}
    </Stack>
  );
};

export default SelectedFiltersSection;
