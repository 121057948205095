import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { IntegratedModelsInterface } from 'interfaces/ModelsInterface';

const getIntegratedModels = async () => {
  const response = await axiosApi.get<IntegratedModelsInterface[]>(routes.GET_INTEGRATED_MODELS());
  return response.data;
};

export { getIntegratedModels };
