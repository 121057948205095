import { KeyboardArrowDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useState, SyntheticEvent } from 'react';

import { colorsTheme } from 'themes/theme';
import MultiMetricTable from '../MultiMetricTable';
import useMultiMetricTable from 'hooks/useMultiMetricTable';
import LoadingState from 'components/LoadingState';
import ErrorState from 'components/ErrorState';
import IconWrapper from 'components/IconWrapper';

interface Props {
  id: string;
  name: string;
  selectedModels: string[];
  selectedCriteria: string[];
  iconSrc?: string;
}

const MultiMetricAccordionItem = ({
  id,
  name,
  iconSrc,
  selectedCriteria,
  selectedModels,
}: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const { tableData, isError, isLoading, isRefetching, refetch } = useMultiMetricTable({
    id,
    models: selectedModels,
    criteria: selectedCriteria,
  });

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    isExpanded && refetch();
  };

  return (
    <Accordion expanded={expanded === `panel${id}`} onChange={handleChange(`panel${id}`)}>
      <AccordionSummary
        aria-label={`${name} card`}
        expandIcon={<KeyboardArrowDown fontSize="small" htmlColor={colorsTheme.border.color4} />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        sx={{ pt: 2.5, px: 3, pb: 1.75 }}
      >
        <Box display="flex" alignItems="center">
          <IconWrapper>
            <img src={iconSrc} width={24} height="auto" alt="" loading="lazy" />
          </IconWrapper>
          <Box marginLeft={2}>
            {name && (
              <Typography component="h3" variant="h5">
                {name}
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {(isLoading || isRefetching) && <LoadingState testId={`multimetric-table-${id}-spinner`} />}
        {isError && <ErrorState refetch={refetch} />}
        {tableData && <MultiMetricTable tableData={tableData} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default MultiMetricAccordionItem;
