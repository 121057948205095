import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { getCostOptimizationScenarios } from 'api/services/CostOptimizationScenarioService';

const useCostOptimizationScenarios = () => {
  const {
    data: costOptimizationScenariosData,
    isLoading,
    isError,
    error: costOptimizationScenariosError,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.COST_OPTIMIZATION_SCENARIOS],
    queryFn: () => getCostOptimizationScenarios(),
    refetchOnWindowFocus: false,
  });

  return {
    costOptimizationScenariosData,
    isError,
    isLoading,
    costOptimizationScenariosError,
    isFetched,
    refetch,
  };
};

export default useCostOptimizationScenarios;
