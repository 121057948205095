import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';

import theme, { colorsTheme } from 'themes/theme';

export const StyledModelPickerInput = styled(TextField)({
  backgroundColor: colorsTheme.background.color1,
  width: '100%',
  marginLeft: '1px',

  '& .MuiOutlinedInput-root': {
    fontSize: 12,
    padding: `0 0 0 ${theme.spacing(1)}`,
    '& fieldset': { borderColor: 'transparent' },
    '&.Mui-focused fieldset': { color: colorsTheme.text.neutral, borderColor: 'transparent' },
    '&:hover fieldset': { borderColor: 'transparent' },
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    transform: `translate(${theme.spacing(1.25)}, ${theme.spacing(1.25)}) scale(1) !important`,
  },
  '& .MuiInputLabel-root[data-shrink="true"]': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
});

export const StyledLabelWrapper = styled(Box)({
  display: 'flex',
  color: colorsTheme.text.neutral,
  alignItems: 'center',
  gap: theme.spacing(0.5),
});
