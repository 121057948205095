import { useMutation } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { SendEmailProps, IncreaseShares, SendEmail } from 'api/services/ShareModelService';
import toast from 'react-hot-toast';

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const useShare = () => {
  const { mutate: mutateEmail, isSuccess: isEmailSuccess } = useMutation({
    mutationKey: [QueryKeys.SEND_EMAIL],
    mutationFn: (payload: SendEmailProps) => {
      const shareType = payload.type.slice(0, -1);
      return toast.promise(
        SendEmail(payload),
        {
          loading: 'Sharing...',
          success: `${capitalizeFirstLetter(shareType)} shared.`,
          error: `Error sharing ${shareType}.`,
        },
        {
          id: 'shareToast',
          loading: {
            duration: Infinity,
          },
          success: {
            duration: 2000,
          },
          error: {
            duration: 2000,
          },
        }
      );
    },
    meta: { hideDefaultToast: true },
  });

  const { mutate: mutateShares, isSuccess: isSharesSuccess } = useMutation({
    mutationKey: [QueryKeys.INCREASE_SHARES],
    mutationFn: IncreaseShares,
  });

  return {
    isEmailSuccess,
    isSharesSuccess,
    sendEmail: mutateEmail,
    increaseShares: mutateShares,
  };
};

export default useShare;
