import { createContext, useContext } from 'react';
import { getToken, isAuthenticated, setToken } from './authUtils';

type AuthContextProps = {
  userToken: undefined | null | string;
  isAuthenticated: () => boolean;
  setToken: (token: string) => void;
};

const AuthContext = createContext<AuthContextProps>({
  userToken: getToken(),
  isAuthenticated,
  setToken,
});

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);
AuthContext.displayName = 'AuthContext';

export default AuthContext;
