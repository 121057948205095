import { Box, styled, Typography } from '@mui/material';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';

export const StyledMetricsBox = styled(Box)({
  backgroundColor: colorsTheme.background.color2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxHeight: theme.spacing(6.5),
  gap: 8,
  overflow: 'hidden',
  padding: `${theme.spacing(2.25)} ${theme.spacing(2)}`,
});

export const MetricBoxWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: 12,
});

export const StyledMetricTypography = styled(Typography)({
  fontSize: 'inherit',
  letterSpacing: 0.3,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const DescriptionText = styled(Typography)({
  paddingTop: 12,
  paddingBottom: 8,
  boxSizing: 'border-box',
  fontSize: 12,
  width: '100%',
  overflow: 'hidden',
  height: theme.spacing(6.5),
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textAlign: 'start',
  letterSpacing: 0.3,
});

export const ModelComparisonCardWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  scrollSnapAlign: 'center',
  justifyContent: 'center',
  minWidth: '100%',
  backgroundColor: colorsTheme.text.light,
  textAlign: 'center',
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  borderRadius: 8,
  gap: 12,
  boxShadow: shadowTheme.card,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    scrollSnapAlign: 'none',
    minWidth: 'unset',
    flexBasis: 'calc(50% - 12px)',
    maxWidth: 'calc(50% - 12px)',
  },
});

export const MetricsSummaryWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 20,
  fontSize: 12,
});

export const ModelImageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '100%',
  backgroundColor: colorsTheme.background.color4,
  border: '2px solid rgba(215, 215, 215, 0.3)',
});
