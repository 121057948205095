import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'api/queryKeys';
import {
  getMultiMetricComparisonData,
  MultiMetricComparisonDataQueryParams,
} from 'api/services/MultiMetricComparisonService';

const useMultiMetricComparison = ({ themes, categories }: MultiMetricComparisonDataQueryParams) => {
  const {
    data: multiMetricComparisonData,
    isLoading,
    isError,
    error: multiMetricComparisonDataError,
    isRefetching,
    refetch,
  } = useQuery({
    queryFn: () => getMultiMetricComparisonData({ themes, categories }),
    queryKey: [
      QueryKeys.MULTI_METRIC_COMPARISON_DATA,
      `selectedThemes:[${themes}]`,
      `selectedCategories:[${categories}]`,
    ],
    refetchOnWindowFocus: false,
  });

  return {
    multiMetricComparisonData,
    isError,
    isLoading,
    multiMetricComparisonDataError,
    refetch,
    isRefetching,
  };
};

export default useMultiMetricComparison;
