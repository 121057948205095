import { useContext, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { AppContext } from 'context/AppContext';
import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';
import { calculateCheckedThemes, updateThemeState } from 'utils/MultimetricForm/formUtils';

export const useThemesFilter = () => {
  const { control, setValue } = useFormContext<MultimetricSelectedFilters>();
  const {
    state: {
      application: { themes },
    },
  } = useContext(AppContext);

  const themesPicker = useWatch({ control, name: 'themesPicker' });
  const categoriesPicker = useWatch({ control, name: 'categoriesPicker' });

  const totalCheckedThemes = calculateCheckedThemes(themesPicker);

  const updateThemesState = useCallback(() => {
    themes.forEach((theme) => {
      const themeState = updateThemeState(theme, categoriesPicker);
      setValue(`themesPicker.${theme.id}`, themeState, { shouldDirty: true });
    });
  }, [themes, categoriesPicker, setValue]);

  useEffect(() => {
    updateThemesState();
  }, [categoriesPicker, updateThemesState]);

  const handleThemeChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const selectedThemeIds = event.target.value as string[];

      themes.forEach((theme) => {
        const wasChecked = themesPicker[theme.id]?.checked;
        const isNowChecked = selectedThemeIds.includes(theme.id);

        if (wasChecked !== isNowChecked) {
          setValue(`themesPicker.${theme.id}.checked`, isNowChecked, { shouldDirty: true });

          theme.categories.forEach((category) => {
            setValue(`categoriesPicker.${category.id}.checked`, isNowChecked, {
              shouldDirty: true,
            });
            if (isNowChecked) {
              setValue(`categoriesPicker.${category.id}.indeterminate`, false, {
                shouldDirty: true,
              });
            }
            category.criteria.forEach((criterion) => {
              setValue(`categoriesPicker.${category.id}.criteria.${criterion.id}`, isNowChecked, {
                shouldDirty: true,
              });
            });
          });
        }
      });

      updateThemesState();
    },
    [themes, themesPicker, setValue, updateThemesState]
  );

  return {
    themes,
    themesPicker,
    categoriesPicker,
    totalCheckedThemes,
    handleThemeChange,
  };
};
