import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { RoutesVars } from 'const/constRoutes';
import { ComparisonTypes } from 'const/constants';
import theme from 'themes/theme';

const ToggleCompareType = ({ compareType }: { compareType: string }) => {
  const navigate = useNavigate();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box>
      <ToggleButtonGroup
        color="primary"
        value={compareType}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{ display: 'none', [theme.breakpoints.up('md')]: { display: 'block' } }}
      >
        <ToggleButton
          data-testid="multimetric-toggle-button"
          value={`/${RoutesVars.COMPARE_MULTI_METRICS}`}
        >
          {ComparisonTypes.MULTI_METRICS}
        </ToggleButton>
        <ToggleButton
          data-testid="cost-optimization-toggle-button"
          value={`/${RoutesVars.COMPARE_COST_OPTIMIZATION}`}
        >
          {ComparisonTypes.COST_OPTIMIZATION}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToggleCompareType;
