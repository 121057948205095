import React from 'react';
import { Box, Divider, Fade, Tooltip } from '@mui/material';
import { ComparisonModelInterface } from 'interfaces/ComparisonInterface';
import {
  StyledMetricsBox,
  StyledMetricTypography,
  MetricBoxWrapper,
  ModelComparisonCardWrapper,
  DescriptionText,
  MetricsSummaryWrapper,
  ModelImageWrapper,
} from './styled';
import { colorsTheme } from 'themes/theme';

interface Props extends ComparisonModelInterface {
  inputTokens: number;
  outputTokens: number;
  requestsPerDay: number;
}

interface Metrics {
  title: string;
  value: number | string;
  unit: string;
}

const formatNumber = (num: number): string => new Intl.NumberFormat().format(num);

const ModelComparisonCard = (props: Props) => {
  const inputCriteriaMetrics: Metrics[] = [
    {
      title: 'Total number of requests / day',
      value: formatNumber(props.requestsPerDay),
      unit: 'requests / day',
    },
    {
      title: 'Number of input tokens / request',
      value: formatNumber(props.inputTokens),
      unit: 'tokens / request',
    },
    {
      title: 'Number of output tokens / request',
      value: formatNumber(props.outputTokens),
      unit: 'tokens / request',
    },
  ];

  const costPerTokenMetrics: Metrics[] = [
    { title: 'Input tokens', value: props.millionInputTokensCost, unit: '$ / million tokens' },
    { title: 'Output tokens', value: props.millionOutputTokensCost, unit: '$ / million tokens' },
  ];

  const totalCostMetrics: Metrics[] = [
    { title: 'Cost / day', value: formatNumber(props.costPerDay), unit: '$ / day' },
    { title: 'Cost / month', value: formatNumber(props.costPerMonth), unit: '$ / month' },
  ];

  const MetricItem = ({ title, value, unit }: Metrics) => {
    const formattedValue = unit.includes('$') ? `$${value}` : value;
    const formattedUnit = unit.replace('$', '').trim();
    return (
      <StyledMetricsBox>
        <StyledMetricTypography>{title}</StyledMetricTypography>
        <StyledMetricTypography
          fontWeight="600"
          sx={{
            textAlign: 'right !important',
          }}
          color={title.startsWith('Cost') ? colorsTheme.text.total : 'inherit'}
        >
          {formattedValue} {formattedUnit}
        </StyledMetricTypography>
      </StyledMetricsBox>
    );
  };

  const MetricSection = ({ title, metrics }: { title: string; metrics: Metrics[] }) => (
    <MetricBoxWrapper>
      <StyledMetricTypography
        fontWeight="500"
        color={title === 'Total' ? colorsTheme.text.total : 'inherit'}
      >
        {title}
      </StyledMetricTypography>
      <Box width="100%">
        {metrics.map((metric, index) => (
          <React.Fragment key={metric.title}>
            <MetricItem {...metric} />
            {index < metrics.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    </MetricBoxWrapper>
  );

  return (
    <ModelComparisonCardWrapper>
      <Box display="flex" gap={0.5} alignItems="center">
        <ModelImageWrapper>
          <img
            src={props.iconSrc.startsWith('http') ? props.iconSrc : `http://${props.iconSrc}`}
            alt=""
            width={20}
          />
        </ModelImageWrapper>
        <StyledMetricTypography textTransform="uppercase" fontWeight="500">
          {props.title}
        </StyledMetricTypography>
      </Box>
      <Box width="100%" display="flex" alignItems="center">
        <Tooltip
          title={props.description}
          placement="bottom"
          TransitionComponent={Fade}
          enterTouchDelay={0}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <DescriptionText>{props.description}</DescriptionText>
        </Tooltip>
      </Box>
      <MetricsSummaryWrapper>
        <MetricSection title="Input criteria" metrics={inputCriteriaMetrics} />
        <MetricSection title="Cost / token" metrics={costPerTokenMetrics} />
        <MetricSection title="Total" metrics={totalCostMetrics} />
      </MetricsSummaryWrapper>
    </ModelComparisonCardWrapper>
  );
};

export default ModelComparisonCard;
