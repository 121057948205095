import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { CostOptimizationScenariosInterface } from 'interfaces';

const getCostOptimizationScenarios = async () => {
  const response = await axiosApi.get<CostOptimizationScenariosInterface[]>(
    routes.COST_OPTIMIZATION_SCENARIOS()
  );
  return response.data;
};

export { getCostOptimizationScenarios };
