import { Box, Fade, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink } from 'react-router-dom';
import { ReactElement, useState } from 'react';

import {
  CompareButton,
  ComparisonCardWrapper,
  ContentWrapper,
  FooterWrapper,
  HeadingBox,
  HeadingWrapper,
  StyledUploadIcon,
  FooterButton,
} from './helpers/styled';
import theme, { colorsTheme } from 'themes/theme';
import { SingleComparison } from 'interfaces/ComparisonInterface';
import ModelComparisonCard from '../ModelComparisonCard';
import { RoutesVars } from 'const/constRoutes';
import useGetComparisonExportFile from 'hooks/useGetComparisonExportFile';
import { slugifyString } from 'utils/slugifyString';
import { handleFileDownloadFromApi } from 'utils/handleFileDownloadFromApi';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.comparisonCard;

interface Props {
  comparisonData: SingleComparison;
  handleShareComparison: (id: string) => void;
}

const ComparisonCard = ({ comparisonData, handleShareComparison }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { refetch, isFetching } = useGetComparisonExportFile({
    comparisonId: comparisonData.id,
  });

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleDownload = () =>
    handleFileDownloadFromApi({
      refetch,
      filename: `models-comparison-${slugifyString(comparisonData.title)}.csv`,
    });

  const TooltipComponent = ({ title, children }: { title: string; children: ReactElement }) => (
    <Tooltip
      title={title}
      placement="top"
      enterTouchDelay={0}
      TransitionComponent={Fade}
      slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
    >
      {children}
    </Tooltip>
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  return (
    <ComparisonCardWrapper>
      <HeadingWrapper>
        <Box
          gap={0.75}
          display="flex"
          maxWidth={{ xs: '90%', md: '75%' }}
          alignItems="center"
          whiteSpace="nowrap"
        >
          <TooltipComponent title={comparisonData.title}>
            <Typography
              variant="h6"
              component="h3"
              flex="0 1 auto"
              py={1}
              px={1.5}
              sx={{
                backgroundColor: colorsTheme.background.color4,
                borderRadius: 2,
                maxWidth: '50%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {comparisonData.title}
            </Typography>
          </TooltipComponent>
          <TooltipComponent title={comparisonData.label}>
            <Typography variant="h6" flex={1} textOverflow="ellipsis" overflow="hidden">
              {comparisonData.label}
            </Typography>
          </TooltipComponent>
        </Box>
        <HeadingBox>
          <NavLink
            to={`/${RoutesVars.COMPARE_COST_OPTIMIZATION}?comparisonId=${comparisonData.id}`}
            style={{ textDecoration: 'none' }}
          >
            <CompareButton variant="outlined">
              <Typography variant="h6" sx={{ color: colorsTheme.primary.main }}>
                {strings.compareText}
              </Typography>
            </CompareButton>
          </NavLink>
          <IconButton
            onClick={handleDownload}
            disabled={isFetching}
            sx={{
              '&:hover': { background: 'none', '& svg': { color: colorsTheme.primary.main } },
              display: 'none',
              [theme.breakpoints.up('md')]: { display: 'flex' },
            }}
          >
            <StyledUploadIcon />
          </IconButton>
          <IconButton
            sx={{
              '&:hover': { background: 'none', '& svg': { color: colorsTheme.primary.main } },
              display: 'flex',
              [theme.breakpoints.up('md')]: { display: 'none' },
            }}
            onClick={handleClick}
            aria-controls={menuOpen ? 'mobile-view-menu' : undefined}
            aria-expanded={menuOpen}
            aria-haspopup={true}
          >
            <MoreVertIcon sx={{ width: 20, color: colorsTheme.icon.primary }} />
          </IconButton>
          <Menu
            id="mobile-view-menu"
            aria-labelledby="mobile-view-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <NavLink
              to={`/${RoutesVars.COMPARE_COST_OPTIMIZATION}?comparisonId=${comparisonData.id}`}
              style={{ textDecoration: 'none' }}
            >
              <MenuItem onClick={handleClose} sx={{ textDecoration: 'none' }}>
                {strings.compareText}
              </MenuItem>
            </NavLink>
            <MenuItem onClick={handleClose}>
              <div tabIndex={0} onClick={handleDownload} role="button">
                {strings.exportText}
              </div>
            </MenuItem>
          </Menu>
        </HeadingBox>
      </HeadingWrapper>
      <ContentWrapper>
        {comparisonData.models.map((data) => (
          <ModelComparisonCard
            {...data}
            key={data.id}
            inputTokens={comparisonData.inputTokens}
            outputTokens={comparisonData.outputTokens}
            requestsPerDay={comparisonData.requestsPerDay}
          />
        ))}
      </ContentWrapper>
      <FooterWrapper>
        <Box sx={{ display: 'flex', gap: 1.25 }}>
          <FooterButton onClick={() => handleShareComparison(comparisonData.id)}>
            <ShareIcon sx={{ width: 16, color: colorsTheme.icon.neutral }} />
          </FooterButton>
        </Box>
      </FooterWrapper>
    </ComparisonCardWrapper>
  );
};

export default ComparisonCard;
