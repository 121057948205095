import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import {
  getConversationExportFileService,
  getConversationExportFileServiceParams,
} from 'api/services/GetConversationExportFileService';

const useGetConversationExportFile = ({
  conversationId,
}: getConversationExportFileServiceParams) => {
  const { data, isLoading, isError, error, isFetched, isFetching, refetch } = useQuery({
    queryKey: [QueryKeys.GET_COMPARISON_EXPORT_FILE, conversationId],
    queryFn: () => getConversationExportFileService({ conversationId }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    data,
    isError,
    isLoading,
    error,
    isFetched,
    isFetching,
    refetch,
  };
};

export default useGetConversationExportFile;
