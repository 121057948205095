export function formatSharesNumber(num: number): string {
  if (num < 0) {
    return '0';
  }
  if (num < 1000) {
    return num.toString();
  } else {
    const thousands = Math.floor(num / 1000);
    const remainder = num % 1000;
    const hundreds = Math.floor(remainder / 100);

    if (hundreds > 0) {
      return `${thousands}.${hundreds}K`;
    } else {
      return `${thousands}K`;
    }
  }
}
