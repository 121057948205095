import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'api/queryKeys';
import {
  getMultiMetricTableData,
  MultiMetricTableQueryParams,
} from 'api/services/MultiMetricTableService';

const useMultiMetricTable = ({ id, models, criteria }: MultiMetricTableQueryParams) => {
  const {
    data: tableData,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery({
    queryFn: () => getMultiMetricTableData({ id, criteria, models }),
    queryKey: [
      QueryKeys.MULTI_METRIC_COMPARISON_TABLE,
      id,
      `selectedModels:[${models}]`,
      `selectedCriteria:[${criteria}]`,
    ],
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    tableData,
    isError,
    isLoading,
    isRefetching,
    refetch,
  };
};

export default useMultiMetricTable;
