import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import ErrorState from 'components/ErrorState';
import LoadingState from 'components/LoadingState';
import { SectionHeading } from 'components/SectionHeading';
import ShareModel from 'components/ShareModal';
import useModels from 'hooks/useModels';
import { ModelCard } from 'pages/HomePage/components/ModelCard';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { formatSharesNumber } from 'utils/formatSharesNumber';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.allModelsPage;

const AllModelsPage = () => {
  const { isError, isLoading, modelsData, refetch } = useModels({});
  const [open, setOpen] = useState(false);
  const [modelId, setModelId] = useState('');

  const handleShareModel = (id: string) => {
    setOpen(true);
    setModelId(id);
  };

  const renderGrid = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (isError) {
      return <ErrorState refetch={refetch} />;
    }

    if (!modelsData?.length || !modelsData) {
      return <ErrorState refetch={refetch} errorMessage="No models found." />;
    }

    return modelsData?.map(({ title, description, id, shares, iconSrc }) => {
      const formattedShares = formatSharesNumber(shares);
      return (
        <Grid item sm={6} key={id} sx={{ width: '100%', minWidth: 275 }}>
          <ModelCard
            title={title}
            description={description}
            shares={formattedShares}
            iconSrc={iconSrc}
            modelId={id}
            handleShareModel={handleShareModel}
          />
        </Grid>
      );
    });
  };

  return (
    <Box width="100%" pt={5}>
      <ShareModel isOpen={open} id={modelId} setIsOpen={setOpen} />
      <NavLink to="/">
        <Button
          size="small"
          variant="text"
          sx={{ mb: 1.75 }}
          startIcon={<ArrowBack sx={{ width: 14 }} />}
        >
          {strings.backHomeLinkText}
        </Button>
      </NavLink>

      <SectionHeading headingText={strings.pageTitle} />
      <Grid container spacing={2} marginTop={2}>
        {renderGrid()}
      </Grid>
    </Box>
  );
};

export default AllModelsPage;
