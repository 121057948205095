import { Box, Typography, Button, styled } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import theme from 'themes/theme';

const ErrorWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  minHeight: theme.spacing(60),
});

interface ErrorStateProps {
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult>;
  errorMessage?: string;
}

const ErrorState = ({ refetch, errorMessage = 'Something went wrong.' }: ErrorStateProps) => {
  return (
    <ErrorWrapper>
      <Typography aria-live="polite" role="alert">
        {errorMessage}
      </Typography>

      <Box mt={2}>
        <Button autoFocus size="large" variant="outlined" onClick={() => refetch()}>
          Try again
        </Button>
      </Box>
    </ErrorWrapper>
  );
};

export default ErrorState;
