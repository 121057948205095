import { Box, BoxProps } from '@mui/material';
import { colorsTheme } from 'themes/theme';

interface Props extends BoxProps {
  children: React.ReactNode;
  borderSize?: string;
  borderRadius?: number;
}

const GradientBorderWrapper = ({
  children,
  borderSize = '1px',
  borderRadius = 2,
  ...rest
}: Props) => {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(90deg, white, white), linear-gradient(90deg, ${colorsTheme.primary.main} , ${colorsTheme.secondary.main})`,
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'border-box',
        border: `${borderSize} solid transparent`,
        borderRadius,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default GradientBorderWrapper;
