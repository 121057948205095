import { Box, Button, Typography } from '@mui/material';
import { useQuery } from 'App';
import NumberInput from 'components/FormFields/NumberInput';
import useComparison from 'hooks/useComparison';
import { SingleComparison } from 'interfaces/ComparisonInterface';
import { PlaygroundFormSchema } from 'pages/CompareCostOptimizationPage/components/CostOptimizationFormWrapper';
import ModelPickerFilter from 'pages/CompareCostOptimizationPage/components/FilterFields/ModelPickerFilter';
import ScenariosPickerInput from 'pages/CompareCostOptimizationPage/components/FilterFields/ScenariosPickerFilter';
import SearchInputCostOptimization from 'pages/CompareCostOptimizationPage/components/FilterFields/SearchInputCostOptimization';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import theme from 'themes/theme';
import stringsJSON from 'assets/strings/strings.json';
interface Props {
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<SingleComparison | null>>;
}

const strings = stringsJSON.costOptimizationPage;

const CostOptimizationFilterSection = ({ setSelectedScenarioData }: Props) => {
  const comparisonQuery = useQuery().get('comparisonId');
  const { comparisonData } = useComparison({ id: comparisonQuery || '' });
  const {
    formState: { errors },
    setValue,
  } = useFormContext<PlaygroundFormSchema>();
  const hasErrors = Object.keys(errors).length > 0;
  const hasNumberErrors =
    Boolean(errors.numberOfInputTokensPerRequest) ||
    Boolean(errors.numberOfOutputTokensPerRequest) ||
    Boolean(errors.totalNumberOfRequestsPerDay);

  const numberErrorMsg =
    errors.numberOfInputTokensPerRequest?.message ||
    errors.numberOfOutputTokensPerRequest?.message ||
    errors.totalNumberOfRequestsPerDay?.message;

  function getErrorMessage() {
    if (errors.modelPicker) {
      return errors.modelPicker.message;
    }

    if (hasNumberErrors) {
      return numberErrorMsg;
    }

    return strings.allFieldsRequiredErrorMessage;
  }

  useEffect(() => {
    if (!comparisonQuery || !comparisonData) return;

    const { models, inputTokens, outputTokens, requestsPerDay, scenarioId } = comparisonData[0];

    models.forEach(({ id }) => {
      setValue(`modelPicker.${id}`, { checked: true }, { shouldDirty: true });
    });

    const formUpdates = {
      numberOfInputTokensPerRequest: inputTokens,
      numberOfOutputTokensPerRequest: outputTokens,
      totalNumberOfRequestsPerDay: requestsPerDay,
      scenarioPicker: scenarioId,
    };

    Object.entries(formUpdates).forEach(([key, value]) =>
      setValue(key as keyof PlaygroundFormSchema, value.toString())
    );

    setSelectedScenarioData(comparisonData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonData, comparisonQuery]);

  return (
    <Box mt={{ xs: 4, md: 5.25 }}>
      <Box
        display="flex"
        maxWidth="100%"
        alignContent="center"
        gap={1.5}
        mb={1.25}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Box minWidth={{ xs: '50%', md: '62.5%' }} flexGrow={1}>
          <SearchInputCostOptimization />
        </Box>
        <Box flexGrow={1}>
          <ModelPickerFilter />
        </Box>
      </Box>
      <Box display="flex" gap={1.5} alignItems="center" flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
        <Box width={{ xs: '100%', md: '40%' }} display="flex" gap={1.5}>
          <Box width={{ xs: '38%', md: '44%' }}>
            <ScenariosPickerInput />
          </Box>

          <Box flexGrow={1}>
            <NumberInput
              inputName={'totalNumberOfRequestsPerDay'}
              ariaLabel={strings.totalNumberOfRequestsPerDayInputText}
              placeholder={strings.totalNumberOfRequestsPerDayInputText}
            />
          </Box>
        </Box>

        <Box width={{ xs: '100%', md: '46.3%' }} display="flex" gap={1.5}>
          <Box width={{ xs: '41%', md: '45%' }}>
            <NumberInput
              inputName={'numberOfInputTokensPerRequest'}
              ariaLabel={strings.numberOfInputTokensPerRequest}
              placeholder={strings.numberOfInputTokensPerRequest}
            />
          </Box>

          <Box flexGrow={1}>
            <NumberInput
              inputName={'numberOfOutputTokensPerRequest'}
              ariaLabel={strings.numberOfOutputTokensPerRequest}
              placeholder={strings.numberOfOutputTokensPerRequest}
            />
          </Box>
        </Box>

        <Box maxWidth={{ xs: 87, md: 116 }} flexGrow={1} ml="auto">
          <Button variant="contained" fullWidth type="submit">
            {strings.submitButtonText}
          </Button>
        </Box>
      </Box>
      {hasErrors && (
        <Typography
          sx={{
            mt: 1,
            fontSize: 14,
            color: theme.palette.warning.main,
          }}
        >
          {`${getErrorMessage()}`}
        </Typography>
      )}
    </Box>
  );
};

export default CostOptimizationFilterSection;
