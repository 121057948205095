import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { isAuthenticated, setToken } from 'context/Auth/authUtils';
import { useQuery } from 'App';
import apiUrl from 'api/routes';

type PrivateRouteProps = { children: JSX.Element };

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticatedUser = isAuthenticated();

  const tokenQuery = useQuery().get('authToken');

  useEffect(() => {
    tokenQuery && setToken(tokenQuery);
  }, [tokenQuery]);

  if (!isAuthenticatedUser) {
    !tokenQuery && window.location.assign(`${apiUrl}/auth/not-authorized`);
  }

  return isAuthenticatedUser && !tokenQuery ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
