import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';

import App from './App';
import AppTheme from 'themes/theme';
import { Toaster } from 'react-hot-toast';
import { StrictMode } from 'react';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./api/mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    // turn off MSW warnings for specific routes
    onUnhandledRequest(req, print) {
      // specify routes to exclude
      const excludedRoutes = ['/media', '/static/js'];

      // check if the req.url.pathname contains excludedRoutes
      const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));

      if (isExcluded) {
        return;
      }

      print.warning();
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline enableColorScheme />
        <App />
        <Toaster toastOptions={{ duration: 3000, position: 'top-center' }} />
      </ThemeProvider>
    </StrictMode>
  );
});
