import { Box, styled } from '@mui/material';
import theme, { colorsTheme } from 'themes/theme';

export const IconWrapperStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.primary.light,
  border: `1px solid ${colorsTheme.drawer.primary}`,
  borderRadius: '100%',
});

interface Props {
  children: React.ReactNode;
  size?: number;
}

const IconWrapper = ({ children, size = 32 }: Props) => {
  return (
    <IconWrapperStyled width={size} height={size}>
      {children}
    </IconWrapperStyled>
  );
};

export default IconWrapper;
