import { Box } from '@mui/material';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import theme from 'themes/theme';
import Navbar from 'components/Navbar';
import MainBodyWrapper from 'components/MainBodyWrapper';

import BackgroundContainerGradients from './BackgroundContainerGradients';

const DefaultLayout: React.FunctionComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        minHeight: '100dvh',
        maxWidth: '1440px',
        mx: 'auto',
        [theme.breakpoints.up('md')]: { mt: 1.25 },
      }}
    >
      <MainBodyWrapper>
        <Box display="flex" flexDirection="column" sx={{ width: '100%', minHeight: '100%' }}>
          <Navbar />
          <Box
            sx={{
              px: 2.5,
              position: 'relative',
              flexGrow: '1',
              [theme.breakpoints.up('md')]: { px: 7.5 },
            }}
          >
            <Box sx={{ position: 'relative', zIndex: 2, maxWidth: '1014px', m: '0 auto' }}>
              <Outlet />
            </Box>
            <BackgroundContainerGradients />
          </Box>
        </Box>
      </MainBodyWrapper>
      <ScrollRestoration />
    </Box>
  );
};

export default DefaultLayout;
