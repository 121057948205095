import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { MultiMetricComparisonInterface } from 'interfaces';

export interface MultiMetricComparisonDataQueryParams {
  themes?: string[];
  categories?: string[];
}

const getMultiMetricComparisonData = async (queryParams?: MultiMetricComparisonDataQueryParams) => {
  const response = await axiosApi.get<MultiMetricComparisonInterface>(
    routes.MULTI_METRIC_COMPARISON({
      themes: queryParams?.themes?.toString(),
      categories: queryParams?.categories?.toString(),
    })
  );
  return response.data;
};

export { getMultiMetricComparisonData };
