import { Popper, styled } from '@mui/material';
import theme, { shadowTheme, colorsTheme } from 'themes/theme';

export const CustomPopper = styled(Popper)({
  boxShadow: shadowTheme.popper,
  borderRadius: theme.spacing(1),
  border: 'none',
  overflow: 'hidden',
  minWidth: 170,
  '& .MuiAutocomplete-paper': {
    boxShadow: 'none',
    margin: 0,
  },
});

export const StyledModelOption = styled('li')({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  paddingTop: '12px !important',
  paddingBottom: '12px !important',

  '&:hover, &.MuiAutocomplete-option.Mui-focused': {
    backgroundColor: colorsTheme.background.optionHover,
  },
});
