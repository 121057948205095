import { Box, Typography } from '@mui/material';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';

import MultiMetricAccordionItem from '../../components/MultiMetricAccordionItem';
import ErrorState from 'components/ErrorState';
import LoadingState from 'components/LoadingState';
import MUItheme from 'themes/theme';
import NoResultFoundState from 'components/NoResultFoundState';
import { MultiMetricComparisonInterface } from 'interfaces';

interface Props {
  multiMetricComparisonData?: MultiMetricComparisonInterface;
  isError: boolean;
  isLoading: boolean;
  selectedModels: string[];
  selectedCriteria: string[];
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<MultiMetricComparisonInterface, Error>>;
}

const ComparisonListSection = ({
  multiMetricComparisonData,
  isError,
  isLoading,
  selectedModels,
  selectedCriteria,
  refetch,
}: Props) => {
  const noResultsFound = !multiMetricComparisonData || !multiMetricComparisonData?.themes?.length;

  if (isLoading) {
    return <LoadingState testId="multimetric-page-comparison-list-spinner" />;
  }

  if (isError) {
    return <ErrorState refetch={refetch} />;
  }

  if (noResultsFound) {
    return <NoResultFoundState />;
  }

  return (
    <Box component="section" sx={{ my: 4.5, [MUItheme.breakpoints.up('md')]: { my: 5.5 } }}>
      {multiMetricComparisonData.themes.map((theme) => (
        <Box key={theme.id}>
          <Typography variant="h3" component="h2" sx={{ my: { xs: 2.25, md: 2.5 } }}>
            {theme.name}
          </Typography>
          <Box>
            {theme.categories.map(({ name, iconSrc, id }) => (
              <MultiMetricAccordionItem
                id={id}
                key={id}
                name={name}
                iconSrc={iconSrc}
                selectedModels={selectedModels}
                selectedCriteria={selectedCriteria}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ComparisonListSection;
