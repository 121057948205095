import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { MultiMetricTableInterface } from 'interfaces';

export interface MultiMetricTableQueryParams {
  id: string;
  models?: string[];
  criteria?: string[];
}

const getMultiMetricTableData = async ({ id, models, criteria }: MultiMetricTableQueryParams) => {
  const response = await axiosApi.get<MultiMetricTableInterface[]>(
    routes.MULTI_METRIC_COMPARISON_TABLE({
      id,
      models: models?.toString(),
      criteria: criteria?.toString(),
    })
  );

  return response.data;
};

export { getMultiMetricTableData };
