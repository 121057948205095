import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface handleDownloadInterface {
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Blob, Error>>;
  filename: string;
}

export const handleFileDownloadFromApi = async ({ refetch, filename }: handleDownloadInterface) => {
  const result = await refetch();
  if (result.data) {
    const blobUrl = window.URL.createObjectURL(result.data);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  }
};
