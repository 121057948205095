import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';
import { Drawer } from '@mui/material';

import { colorsTheme } from 'themes/theme';
import theme from 'themes/theme';

export const StyledNavLink = styled(NavLink)({
  textDecoration: 'none',
  borderRadius: theme.spacing(0.5),
  color: colorsTheme.text.dark,
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 40,
  paddingRight: 40,
  textTransform: 'capitalize',
  border: `${theme.spacing(0.125)} solid transparent`,
  justifyContent: 'flex-start',
  '&.active': {
    backgroundColor: colorsTheme.drawer.secondary,
    boxShadow: `rgba(0, 0, 0, 0.16) 0 ${theme.spacing(0.125)} ${theme.spacing(0.5)}`,
    borderColor: colorsTheme.drawer.primary,
  },
  '& span': { marginLeft: 4 },
});

export const MobileDrawerContainer = styled(Drawer)({
  display: 'flex',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    flexDirection: 'column',
    backgroundColor: colorsTheme.drawer.primary,
    borderRadius: theme.spacing(0.75),
    padding: 4,
    gap: theme.spacing(0.625),
    borderWidth: 0,
    minWidth: theme.spacing(30),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export const DesktopDrawerContainer = styled(Drawer)({
  display: 'none',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    flexDirection: 'row',
    backgroundColor: colorsTheme.drawer.primary,
    borderRadius: theme.spacing(0.75),
    padding: 4,
    gap: theme.spacing(0.625),
    borderWidth: 0,
    position: 'relative',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
});
