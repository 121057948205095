import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import ComparisonListSection from './sections/ComparisonListSection';
import ComparisonFilterSection from './sections/ComparisonFilterSection';
import { AppContext } from 'context/AppContext';
import useMultiMetricComparison from 'hooks/useMultiMetricComparison';
import { FETCH_CATEGORIES, FETCH_CRITERIA, FETCH_MODELS, FETCH_THEMES } from 'context/AppReducer';
import useModels from 'hooks/useModels';
import MultimetricForm from 'utils/MultimetricForm';
import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';
import { QueryKeys } from 'api/queryKeys';
import SelectedFiltersSection from './sections/SelectedFiltersSection';
import ComparePageWelcomeSection from 'components/ComparePageWelcomeSection';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.multimetricComparisonPage;

const CompareMultiMetricsPage = () => {
  const queryClient = useQueryClient();
  const {
    state: {
      application: { models: contextModels, themes: contextThemes },
    },
    dispatch,
  } = useContext(AppContext);

  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [selectedCriteria, setSelectedCriteria] = useState<string[]>([]);

  const { modelsData, isError: isErrorModels, isLoading: isLoadingModels } = useModels({});
  const {
    multiMetricComparisonData,
    isError: isErrorComparison,
    isLoading: isLoadingComparison,
    refetch: refetchComparison,
    isRefetching,
  } = useMultiMetricComparison({ themes: selectedThemes, categories: selectedCategories });

  const isComparisonReady = !isErrorComparison && !isLoadingComparison && multiMetricComparisonData;
  const isModelsReady = !isErrorModels && !isLoadingModels && modelsData;
  const isInitialComparison = contextThemes.length === 1 && contextThemes?.[0].id === '';
  const isInitialModels = contextModels.length === 1 && contextModels?.[0].id === '';

  useEffect(() => {
    if (isComparisonReady && isInitialComparison) {
      dispatch({ type: FETCH_THEMES, payload: [...multiMetricComparisonData.themes] });
      dispatch({
        type: FETCH_CATEGORIES,
        payload: multiMetricComparisonData.themes.map((categories) => categories.categories).flat(),
      });
      dispatch({
        type: FETCH_CRITERIA,
        payload: multiMetricComparisonData.themes.map((categories) =>
          categories.categories.map((criteria) => criteria.criteria).flat()
        ),
      });
    }
  }, [
    dispatch,
    isComparisonReady,
    isErrorComparison,
    isInitialComparison,
    isLoadingComparison,
    multiMetricComparisonData,
  ]);

  useEffect(() => {
    if (isModelsReady && isInitialModels) {
      dispatch({ type: FETCH_MODELS, payload: modelsData });
    }
  }, [dispatch, isInitialModels, isModelsReady, modelsData]);

  const onSubmit = (data: MultimetricSelectedFilters) => {
    const themes: string[] = [];
    const categories: string[] = [];
    const criteria: string[] = [];
    const models: string[] = [];

    Object.entries(data.themesPicker).map(([key, value]) => {
      if (value.checked || value.indeterminate) return themes.push(key);
    });
    Object.entries(data.modelPicker).map(([key, value]) => {
      if (value.checked) return models.push(key);
    });
    Object.entries(data.categoriesPicker).map(([key, value]) => {
      if (value.checked) return categories.push(key);
    });
    Object.entries(data.categoriesPicker).map(([categoryKey, categoryValue]) => {
      Object.entries(categoryValue).map(([, criteriaValueObject]) => {
        Object.entries(criteriaValueObject).map(([criteriaKey, criteriaValue]) => {
          if (criteriaValue) {
            if (!categories.includes(categoryKey)) {
              categories.push(categoryKey);
            }
            criteria.push(criteriaKey);
          }
        });
      });
    });

    setSelectedThemes(themes);
    setSelectedCategories(categories);
    setSelectedCriteria(criteria);
    setSelectedModels(models);

    queryClient.invalidateQueries({ queryKey: [QueryKeys.MULTI_METRIC_COMPARISON_TABLE] });
    queryClient.invalidateQueries({ queryKey: [QueryKeys.MULTI_METRIC_COMPARISON_DATA] });
  };

  //post submit function
  useEffect(() => {
    (selectedThemes || selectedCategories) && refetchComparison();
  }, [refetchComparison, selectedCategories, selectedThemes]);

  return (
    <MultimetricForm onSubmit={onSubmit}>
      <ComparePageWelcomeSection title={strings.pageTitle} description={strings.pageDescription} />
      <ComparisonFilterSection
        isLoading={isLoadingComparison}
        isRefetching={isRefetching}
        onSubmit={onSubmit}
      />
      <SelectedFiltersSection
        onSubmit={onSubmit}
        isRefetching={isRefetching}
        isLoading={isLoadingComparison}
      />
      <ComparisonListSection
        multiMetricComparisonData={multiMetricComparisonData}
        isError={isErrorComparison}
        isLoading={isLoadingComparison || isRefetching}
        refetch={refetchComparison}
        selectedModels={selectedModels}
        selectedCriteria={selectedCriteria}
      />
    </MultimetricForm>
  );
};

export default CompareMultiMetricsPage;
