import { Send } from '@mui/icons-material';
import { Box, Checkbox, IconButton, styled, TextField, Typography } from '@mui/material';
import GradientBorderWrapper from 'components/GradientBorderWrapper';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import theme, { colorsTheme } from 'themes/theme';
import { PlaygroundFieldArray, PlaygroundFormSchemaType } from '../PlaygroundFormWrapper';
import { IntegratedModelsInterface } from 'interfaces';
import { useDebounce } from 'utils/useDebounce';
import { CostOptimizationFormValidation } from 'const/constants';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.playgroundPage;

const StyledPromptWindow = styled(TextField)({
  '& ::placeholder': { color: colorsTheme.text.neutral },
  '& .MuiOutlinedInput-root': {
    fontSize: 14,
    padding: theme.spacing(2.9),
    paddingLeft: theme.spacing(3.7),

    '& .MuiOutlinedInput-input': { minHeight: `${theme.spacing(12)} !important` },
    '& fieldset ': { width: '100%', borderColor: 'transparent' },
    '&.Mui-focused fieldset': { color: colorsTheme.text.neutral, borderColor: 'transparent' },
    '&:hover fieldset': { borderColor: 'transparent' },
  },
});

interface Props {
  onSubmit: SubmitHandler<PlaygroundFormSchemaType>;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  modelsData: IntegratedModelsInterface[];
  playgroundFieldsArray: PlaygroundFieldArray;
}

const PromptWindow = ({ onSubmit, modelsData, setShowErrors, playgroundFieldsArray }: Props) => {
  const { register, watch, handleSubmit, control, setValue, setError, clearErrors } =
    useFormContext<PlaygroundFormSchemaType>();
  const selectedModels =
    playgroundFieldsArray.fields.filter(({ modelId }) =>
      modelsData.some(({ id }) => id === modelId)
    ) || [];
  const atLeastOneSyncChecked = playgroundFieldsArray.fields.some(
    (field) => field.isSynced === true
  );

  const submitData = {
    globalPrompt: watch('globalPrompt'),
    syncAllChats: watch('syncAllChats'),
    cards: selectedModels,
  };

  const handleSyncAllChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue('syncAllChats', checked);

    if (checked === true) {
      playgroundFieldsArray.fields.forEach((field, index) => {
        playgroundFieldsArray.update(index, { ...field, isSynced: true });
      });
    }
  };

  const displayMinModelsErrorMessage = useDebounce({
    func: () => {
      setShowErrors(true);
      setError('cards', {
        message: strings.minModelsErrorMessage,
      });
      setTimeout(() => {
        clearErrors('cards');
        setShowErrors(false);
      }, 5000);
    },
    timer: 200,
  });

  const displaySyncModelError = useDebounce({
    func: () => {
      setShowErrors(true);
      setError('cards', {
        message: strings.syncModelError,
      });
      setTimeout(() => {
        clearErrors('cards');
        setShowErrors(false);
      }, 5000);
    },
    timer: 200,
  });

  const displayLengthErrorMessage = useDebounce({
    func: () => {
      setShowErrors(true);
      setError('cards', {
        message: `${strings.lengthErrorMessage} ${CostOptimizationFormValidation.MAXIMUM_PROMPT_CHARACTERS} ${strings.lengthErrorMessagePart2}`,
      });
      setTimeout(() => {
        clearErrors('cards');
        setShowErrors(false);
      }, 5000);
    },
    timer: 200,
  });

  const handleGlobalSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedModels.length < 1) {
      displayMinModelsErrorMessage();
      return;
    }

    if (watch('globalPrompt').length > CostOptimizationFormValidation.MAXIMUM_PROMPT_CHARACTERS) {
      displayLengthErrorMessage();
      return;
    }

    if (!atLeastOneSyncChecked) {
      displaySyncModelError();
      return;
    }

    setValue('globalPrompt', '');
    handleSubmit(() => {
      onSubmit(submitData);
    })();
  };

  return (
    <GradientBorderWrapper borderSize="2px" borderRadius={3} height={207} mb={5.625}>
      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box sx={{ overflowY: 'auto' }} maxHeight={142} minHeight={142}>
          <StyledPromptWindow
            fullWidth
            multiline
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                if (!watch('globalPrompt')) return;
                handleGlobalSubmit(e);
              }
            }}
            placeholder={strings.globalPromptPlaceholder}
            {...register('globalPrompt', {
              required: true,
              setValueAs: (value: string) => value.trim(),
            })}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%" py={1.5} pr={2} pl={2.3}>
          <Box display="flex" alignItems="center">
            <Controller
              name="syncAllChats"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} onChange={handleSyncAllChange} defaultChecked />
              )}
            />
            <Typography fontSize={12}>{strings.syncAllChatsCheckboxText}</Typography>
          </Box>
          <IconButton
            color="primary"
            sx={{ p: 1 }}
            aria-label={strings.globalPromptSubmitLabel}
            type="submit"
            onClick={(e) => handleGlobalSubmit(e)}
            disabled={!watch('globalPrompt')}
          >
            <Send sx={{ color: colorsTheme.text.neutral }} fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </GradientBorderWrapper>
  );
};

export default PromptWindow;
