import axios, { AxiosResponse } from 'axios';

import { getToken } from 'context/Auth/authUtils';
import apiUrl from './routes';

export const axiosApi = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
});

const tokenExpired = (response: AxiosResponse): boolean => response?.status === 401;

axiosApi.interceptors.request.use((request) => {
  const token = getToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest) return Promise.reject(error);
    if (!originalRequest.request_retried && tokenExpired(error.response)) {
      originalRequest.request_retried = true;
      window.location.assign(`${apiUrl}/auth/not-authorized`);
    }

    return Promise.reject(error);
  }
);
