import { useFormContext } from 'react-hook-form';
import { PlaygroundFormSchemaType } from '../PlaygroundFormWrapper';
import { Typography } from '@mui/material';
import { colorsTheme } from 'themes/theme';

interface Props {
  showErrors: boolean;
}

const PlaygroundFormErrors = ({ showErrors }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<PlaygroundFormSchemaType>();

  if (!showErrors) {
    return null;
  }

  return (
    <Typography mb={{ xs: 1, md: 0 }} color={colorsTheme.text.warning}>
      {errors.cards?.message}
    </Typography>
  );
};

export default PlaygroundFormErrors;
