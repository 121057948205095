import { useContext, useMemo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AppContext } from 'context/AppContext';
import {
  ThemesInterface,
  MultimetricSearchOption,
  Categories,
} from 'interfaces/MultiMetricComparisonInterface';
import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';

export const useSearchInput = () => {
  const { state } = useContext(AppContext);
  const { models, themes, categories, criteria } = state.application;

  const { setValue, watch } = useFormContext<MultimetricSelectedFilters>();

  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<MultimetricSearchOption | null>(null);

  const allOptions = useMemo<MultimetricSearchOption[]>(
    () => [...models, ...themes, ...categories, ...criteria],
    [models, themes, categories, criteria]
  );

  const getOptionLabel = useCallback((option: MultimetricSearchOption): string => {
    if ('name' in option) return option.name;
    if ('title' in option) return option.title;
    return '';
  }, []);

  const updateCategoryState = useCallback(
    (category: Categories) => {
      const allCriteriaChecked = category.criteria.every((criterion) =>
        watch(`categoriesPicker.${category.id}.criteria.${criterion.id}`)
      );
      const anyCriteriaChecked = category.criteria.some((criterion) =>
        watch(`categoriesPicker.${category.id}.criteria.${criterion.id}`)
      );

      setValue(`categoriesPicker.${category.id}.checked`, allCriteriaChecked, {
        shouldDirty: true,
      });
      setValue(
        `categoriesPicker.${category.id}.indeterminate`,
        !allCriteriaChecked && anyCriteriaChecked,
        { shouldDirty: true }
      );
    },
    [setValue, watch]
  );

  const updateThemeState = useCallback(
    (theme: ThemesInterface) => {
      const allCategoriesChecked = theme.categories.every((category) =>
        watch(`categoriesPicker.${category.id}.checked`)
      );
      const anyCategoryCheckedOrIndeterminate = theme.categories.some(
        (category) =>
          watch(`categoriesPicker.${category.id}.checked`) ||
          watch(`categoriesPicker.${category.id}.indeterminate`)
      );

      setValue(`themesPicker.${theme.id}.checked`, allCategoriesChecked, { shouldDirty: true });
      setValue(
        `themesPicker.${theme.id}.indeterminate`,
        !allCategoriesChecked && anyCategoryCheckedOrIndeterminate,
        { shouldDirty: true }
      );
    },
    [setValue, watch]
  );

  const handleOptionSelect = useCallback(
    (option: MultimetricSearchOption | null) => {
      if (option) {
        if ('title' in option) {
          // Model
          setValue(`modelPicker.${option.id}.checked`, true, { shouldDirty: true });
        } else if ('categories' in option) {
          // Theme
          setValue(`themesPicker.${option.id}.checked`, true, { shouldDirty: true });
          option.categories.forEach((category) => {
            setValue(`categoriesPicker.${category.id}.checked`, true, { shouldDirty: true });
            category.criteria.forEach((criterion) => {
              setValue(`categoriesPicker.${category.id}.criteria.${criterion.id}`, true, {
                shouldDirty: true,
              });
            });
          });
        } else if ('criteria' in option) {
          // Category
          setValue(`categoriesPicker.${option.id}.checked`, true, { shouldDirty: true });
          option.criteria.forEach((criterion) => {
            setValue(`categoriesPicker.${option.id}.criteria.${criterion.id}`, true, {
              shouldDirty: true,
            });
          });
          const parentTheme = themes.find((theme) =>
            theme.categories.some((cat) => cat.id === option.id)
          );
          if (parentTheme) updateThemeState(parentTheme);
        } else {
          // Criterion
          const parentCategory = categories.find((cat) =>
            cat.criteria.some((crit) => crit.id === option.id)
          );
          if (parentCategory) {
            setValue(`categoriesPicker.${parentCategory.id}.criteria.${option.id}`, true, {
              shouldDirty: true,
            });
            updateCategoryState(parentCategory);
            const parentTheme = themes.find((theme) =>
              theme.categories.some((cat) => cat.id === parentCategory.id)
            );
            if (parentTheme) updateThemeState(parentTheme);
          }
        }
        setSelectedOption(option);
        setInputValue(getOptionLabel(option));
      } else {
        setSelectedOption(null);
      }
      setInputValue('');
    },
    [setValue, getOptionLabel, themes, categories, updateCategoryState, updateThemeState]
  );

  const handleInputChange = useCallback((newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  return {
    allOptions,
    inputValue,
    selectedOption,
    handleInputChange,
    handleOptionSelect,
    getOptionLabel,
  };
};
