import { useContext, useEffect } from 'react';

import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';
import { AppContext } from 'context/AppContext';
import { useQuery } from 'App';
import ModelPickerAutocomplete from 'components/FormFields/ModelPickerAutocomplete/ModelPickerAutocomplete';
import { useFormContext } from 'react-hook-form';

interface Props {
  onSubmit: (data: MultimetricSelectedFilters) => void;
}

const ModelPickerFilter = ({ onSubmit }: Props) => {
  const modelQuery = useQuery().get('modelId');
  const { getValues, setValue, handleSubmit } = useFormContext<MultimetricSelectedFilters>();

  const {
    state: {
      application: { models },
    },
  } = useContext(AppContext);

  useEffect(() => {
    if (!modelQuery) return;

    const currentModels = getValues().modelPicker;
    const isModelAlreadySelected = currentModels[modelQuery]?.checked;
    const isModelValid = models?.some((model) => model.id === modelQuery);

    if (!isModelAlreadySelected && isModelValid) {
      setValue(`modelPicker.${modelQuery}`, { checked: true }, { shouldDirty: true });

      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, modelQuery, getValues().modelPicker]);

  return <ModelPickerAutocomplete models={models} />;
};

export default ModelPickerFilter;
