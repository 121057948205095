import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Menu } from '@mui/material';
import { AppContext } from 'context/AppContext';
import { useContext, useState } from 'react';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateCheckedCategories } from 'utils/MultimetricForm/formUtils';
import CategoryCheckbox from '../../CategoryCheckbox';
import stringsJSON from 'assets/strings/strings.json';

const strings = stringsJSON.multimetricComparisonPage;

const CategoriesFilterInput = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control } = useFormContext();

  const { state } = useContext(AppContext);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const categoriesPickerState = useWatch({
    control,
    name: 'categoriesPicker',
  });

  const totalCheckedCategories = calculateCheckedCategories(categoriesPickerState);

  return (
    <>
      <Box
        component="button"
        type="button"
        id="categories-filter-toggle"
        aria-controls={open ? 'categories-filter' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          fontSize: 12,
          maxWidth: 360,
          width: '100%',
          background: colorsTheme.background.color1,
          border: '1px solid #F0F2F5',
          boxShadow: shadowTheme.input,
          minHeight: 42,
          color: colorsTheme.text.neutral,
          textAlign: 'left',
          pr: 1,
          pl: 1.5,
          borderRadius: 2,
          '.MuiSvgIcon-fontSizeSmall': {
            transform: 'rotate(0)',
          },
          '&[aria-expanded=true] .MuiSvgIcon-fontSizeSmall': {
            transform: 'rotate(180deg)',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" height="100%">
          <Box>
            {strings.categoriesFilterPlaceholder}{' '}
            {!!totalCheckedCategories && `(${totalCheckedCategories})`}
          </Box>
          <KeyboardArrowDown
            sx={{ '&:hover': { cursor: 'pointer' } }}
            fontSize="small"
            htmlColor={colorsTheme.border.color4}
          />
        </Box>
      </Box>
      <Menu
        id="categories-filter"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: 0.5,
          borderRadius: 2,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transitionDuration={0}
        MenuListProps={{
          'aria-labelledby': 'categories-filter-toggle',
          sx: {
            padding: 0,
            maxHeight: 250,
            [theme.breakpoints.up('md')]: {
              maxHeight: 300,
              width: anchorEl && anchorEl.offsetWidth,
            },
          },
        }}
      >
        {state.application.categories.map((category) => (
          <CategoryCheckbox key={category.name} category={category} />
        ))}
      </Menu>
    </>
  );
};

export default CategoriesFilterInput;
