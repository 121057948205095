import { AppStateInterface } from './AppContext';

export const FETCH_THEMES = 'FETCH_THEMES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CRITERIA = 'FETCH_CRITERIA';
export const FETCH_MODELS = 'FETCH_MODELS';

export type ReducerAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export const appReducer = (state: AppStateInterface, action: ReducerAction): AppStateInterface => {
  switch (action.type) {
    case FETCH_THEMES: {
      return {
        ...state,
        application: { ...state.application, themes: action.payload },
      };
    }
    case FETCH_CATEGORIES: {
      return {
        ...state,
        application: { ...state.application, categories: action.payload },
      };
    }
    case FETCH_CRITERIA: {
      return {
        ...state,
        application: { ...state.application, criteria: action.payload.flat() },
      };
    }
    case FETCH_MODELS: {
      return {
        ...state,
        application: { ...state.application, models: action.payload },
      };
    }

    default:
      return state;
  }
};
