import { Box, styled } from '@mui/material';
import theme from 'themes/theme';

export const AnswerWindowsSection = styled(Box)({
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  WebkitOverflowScrolling: 'touch',
  display: 'flex',
  gap: theme.spacing(2.7),
  '&::-webkit-scrollbar': { display: 'none' },
  '& > *': { scrollSnapAlign: 'start' },
  [theme.breakpoints.up('md')]: { display: 'grid', gridTemplateColumns: '1fr 1fr' },
});
