import useModels from 'hooks/useModels';

import ModelPickerAutocomplete from 'components/FormFields/ModelPickerAutocomplete/ModelPickerAutocomplete';

const ModelPickerFilter = () => {
  const { modelsData: models } = useModels({
    ordering: 'title',
  });

  if (!models) {
    return null;
  }

  return <ModelPickerAutocomplete models={models} />;
};

export default ModelPickerFilter;
