import { Box, Button, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { CardWrapper, ShareButton } from './styled';
import { colorsTheme } from 'themes/theme';
import IconWrapper from 'components/IconWrapper';
import { NavLink } from 'react-router-dom';
import { RoutesVars } from 'const/constRoutes';
import stringsJSON from 'assets/strings/strings.json';
interface Props {
  title: string;
  description: string;
  shares: string;
  iconSrc: string;
  modelId: string;
  handleShareModel: (id: string) => void;
}

const strings = stringsJSON.general;

export const ModelCard = ({
  title,
  description,
  shares,
  iconSrc,
  handleShareModel,
  modelId,
}: Props) => {
  return (
    <CardWrapper>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" marginBottom={1.5}>
          <IconWrapper>
            <img src={iconSrc} width={18} height="auto" alt="" loading="lazy" />
          </IconWrapper>

          <Typography component="h3" variant="h5" marginLeft={1}>
            {title}
          </Typography>
        </Box>

        <Typography component="p" marginBottom={4}>
          {description}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <ShareButton onClick={() => handleShareModel(modelId)}>
          <ShareIcon
            sx={{
              width: 18,
              color: colorsTheme.icon.neutral,
            }}
          />
          <Typography component="p" fontSize={12}>
            {shares}
          </Typography>
        </ShareButton>
        <NavLink to={`/${RoutesVars.COMPARE_MULTI_METRICS}?modelId=${modelId}`}>
          <Button variant="outlined" size="small">
            {strings.moreInfoText}
          </Button>
        </NavLink>
      </Box>
    </CardWrapper>
  );
};
