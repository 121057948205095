import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { ModelsInterface } from 'interfaces';

export interface ModelsDataQueryParams {
  count?: string;
  searchQuery?: string;
  ordering?: string;
}

const getModelsData = async ({ count, searchQuery, ordering }: ModelsDataQueryParams) => {
  const response = await axiosApi.get<ModelsInterface[]>(
    routes.MODELS(count, searchQuery, ordering)
  );
  return response.data;
};

export { getModelsData };
