import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { ModelsDataQueryParams, getModelsData } from 'api/services/ModelsService';

const useModels = ({ count, searchQuery, ordering }: ModelsDataQueryParams) => {
  const {
    data: modelsData,
    isLoading,
    isError,
    error: modelsError,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.MODELS_DATA, count, searchQuery, ordering],
    queryFn: () =>
      getModelsData({
        count,
        searchQuery,
        ordering,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    modelsData,
    isError,
    isLoading,
    modelsError,
    isFetched,
    refetch,
  };
};

export default useModels;
