import { KeyboardArrowDown, Send } from '@mui/icons-material';
import { Box, Checkbox, IconButton, InputBase, MenuItem, Typography } from '@mui/material';
import useGetIntegratedModels from 'hooks/useGetIntegratedModels';
import theme, { colorsTheme } from 'themes/theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  AnswerModelCardWrapper,
  HeaderModelCard,
  HeaderIconsWrapper,
  PromptInputWrapper,
  StyledModelDropdown,
  StyledListItemText,
} from './styled';
import { v4 as uuidv4 } from 'uuid';
import {
  emptyModelCard,
  PlaygroundFieldArray,
  PlaygroundFormSchemaType,
} from '../PlaygroundFormWrapper';
import { IntegratedModelsInterface } from 'interfaces';
import useMutateLlmQuery from 'hooks/useMutateLlmQuery';
import { useEffect, useState } from 'react';
import LoadingState from 'components/LoadingState';
import { GlobalPromptError } from 'pages/PlaygroundPage';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'utils/useDebounce';
import { CostOptimizationFormValidation } from 'const/constants';
import { SingleLlmMessage } from 'api/services/LlmQueryService';
import ConversationBubble from 'components/ChatBubble';
import DownloadConversationButton from '../DownloadConversationButton';
import stringsJSON from 'assets/strings/strings.json';
interface Props {
  globalError: boolean;
  modelId: string;
  setGlobalError: React.Dispatch<React.SetStateAction<GlobalPromptError[] | undefined>>;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  globalLoading: boolean;
  playgroundFieldsArray: PlaygroundFieldArray;
  cardIndex: number;
  syncAllChatsEnabled: boolean;
  conversationData: SingleLlmMessage[];
  conversationId: string;
}

const strings = stringsJSON.playgroundPage;

const AnswersModelWindow = ({
  cardIndex,
  playgroundFieldsArray: { remove, replace, fields, append, update },
  setGlobalError,
  setShowErrors,
  globalError,
  modelId,
  globalLoading,
  syncAllChatsEnabled,
  conversationData,
  conversationId,
}: Props) => {
  const { integratedModelsData } = useGetIntegratedModels();
  const [localPrompt, setLocalPrompt] = useState('');
  const { sendQuery, isError, isLoading, isSuccess, responseData } = useMutateLlmQuery();
  const { control, watch, setError, clearErrors } = useFormContext<PlaygroundFormSchemaType>();

  const availableModels = integratedModelsData?.filter(
    ({ id }) => !fields.some(({ modelId }) => modelId === id)
  );
  const isSynced = watch(`cards.${cardIndex}.isSynced`);
  const isCardLoading = isSynced && (isLoading || globalLoading);
  const isModelSelected = integratedModelsData?.some(({ id }) => id === modelId);
  const modelExists = integratedModelsData?.some(({ id }) => id === modelId);
  const selectedModel = integratedModelsData?.find(({ id }) => id === modelId);

  const handleAddCard = () => {
    if (fields.length >= 4) return;
    append({
      ...emptyModelCard,
      modelId: uuidv4(),
      isEmpty: true,
      conversationId: '',
    });
  };

  const handleRemoveCard = () => {
    remove(cardIndex);
  };

  const handleOnChange = (id: string) => {
    const modelAlreadySelected = fields.some((model) => model.modelId === id);
    if (modelAlreadySelected) return;

    update(cardIndex, {
      ...emptyModelCard,
      modelId: id,
      isEmpty: false,
      conversationId: '',
    });
  };

  const displayLengthErrorMessage = useDebounce({
    func: () => {
      setShowErrors(true);
      setError('cards', {
        message: `${strings.lengthErrorMessage} ${CostOptimizationFormValidation.MAXIMUM_PROMPT_CHARACTERS} ${strings.lengthErrorMessagePart2}`,
      });
      setTimeout(() => {
        clearErrors('cards');
        setShowErrors(false);
      }, 5000);
    },
    timer: 200,
  });
  const handleSubmit = () => {
    setGlobalError((prev) => prev?.filter(({ id }) => id !== modelId));

    if (localPrompt.length > CostOptimizationFormValidation.MAXIMUM_PROMPT_CHARACTERS) {
      displayLengthErrorMessage();
      return;
    }

    setLocalPrompt('');
    sendQuery({
      conversationId: conversationId || null,
      modelId,
      prompt: localPrompt.trim(),
    });
  };

  useEffect(() => {
    if (isSuccess && !isLoading) {
      if (!responseData) return;
      const newFields = fields.map((model) =>
        model.modelId === modelId
          ? {
              ...model,
              conversationId: responseData.conversationId,
              conversation: responseData.conversation,
            }
          : model
      );
      replace(newFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess]);

  const renderMenuItem = (model: IntegratedModelsInterface, selected = false) => (
    <MenuItem
      key={model.id}
      value={model.id}
      sx={{
        py: 1.5,
        px: 2,
        '&.Mui-selected': { background: 'transparent' },
        '&:hover, &:focus, &.Mui-selected:focus, &.Mui-selected:hover': {
          background: selected ? 'transparent' : colorsTheme.background.optionHover,
        },
      }}
    >
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <img
          src={model.iconSrc.startsWith('http') ? model.iconSrc : `http://${model.iconSrc}`}
          alt=""
          width={18}
          height={18}
        />
        <StyledListItemText primary={model.title} />
      </Box>
    </MenuItem>
  );

  const renderResponses = () => {
    if (isError || globalError) {
      return (
        <Box display="flex" width="100%" justifyContent="center" alignItems="center">
          <Typography aria-live="polite" role="alert">
            {strings.errorFetchingResponse}
          </Typography>
        </Box>
      );
    }

    if (isCardLoading && modelExists && !conversationData.length) {
      return (
        <Box display="flex" width="100%" justifyContent="center">
          <LoadingState />
        </Box>
      );
    }
    return (
      <Box
        display="flex"
        height="100%"
        flexDirection="column-reverse"
        overflow="auto"
        width="100%"
        sx={{ overflowAnchor: 'auto !important' }}
      >
        <Box display="flex" width="100%" gap={2} py={1} flexDirection="column">
          {conversationData?.map((conv) => (
            <ConversationBubble
              key={conv.id}
              isHuman={conv.type === 'human'}
              message={conv.message}
            />
          ))}
          {isCardLoading && modelExists && conversationData.length && (
            <Box display="flex" width="100%" justifyContent="center">
              <LoadingState wrapperMinHeight={10} />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <AnswerModelCardWrapper>
      <HeaderModelCard>
        <StyledModelDropdown
          labelId="answer-model-select-label"
          id="model-select"
          disabled={isCardLoading}
          value={modelId}
          input={<InputBase />}
          defaultValue=""
          onChange={(e) => handleOnChange(e.target.value as string)}
          sx={{ '& .MuiMenuItem-root': { pl: '0' } }}
          IconComponent={(props) => (
            <KeyboardArrowDown {...props} fontSize="small" htmlColor={colorsTheme.border.color4} />
          )}
          renderValue={(selected) => {
            const foundModel = integratedModelsData?.find(({ id }) => id === selected);
            if (!selected || !foundModel) {
              return (
                <Typography color={colorsTheme.text.neutral} ml={0.6} mt={0.2} fontSize={12}>
                  {strings.selectDefaultText}
                </Typography>
              );
            }
            return foundModel && renderMenuItem(foundModel, true);
          }}
        >
          {availableModels && availableModels.map((model) => renderMenuItem(model))}
        </StyledModelDropdown>
        <HeaderIconsWrapper sx={{ 'button:hover': { color: colorsTheme.primary.main } }}>
          <IconButton
            sx={{ p: 0.75 }}
            onClick={handleAddCard}
            disabled={fields.length >= 4 || isCardLoading}
          >
            <AddCircleOutlineIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <IconButton sx={{ p: 0.75 }} onClick={handleRemoveCard} disabled={isCardLoading}>
            <RemoveCircleOutlineIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <DownloadConversationButton
            conversationId={conversationId}
            modelName={selectedModel?.title}
          />
        </HeaderIconsWrapper>
      </HeaderModelCard>
      <Box
        height={570}
        display="flex"
        px={2.8}
        width="100%"
        sx={{ overflowX: 'hidden', overflowY: 'auto' }}
      >
        {renderResponses()}
      </Box>
      <PromptInputWrapper>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          minHeight={40}
          sx={{
            border: `1px solid ${colorsTheme.border.gray}`,
            borderRadius: theme.spacing(1.5),
            px: 0.3,
            width: '100%',
            gap: theme.spacing(1),
            backgroundColor: colorsTheme.background.color1,
          }}
        >
          <InputBase
            sx={{ mx: 1.5, flex: 1, '& ::placeholder': { fontSize: 14 }, width: '94%' }}
            placeholder={
              !conversationData.length
                ? strings.singleCardPromptPlaceholder
                : strings.singleCardPromptFollowUpPlaceholder
            }
            inputProps={{ 'aria-label': strings.singleCardPromptLabel }}
            value={localPrompt}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (!localPrompt || !isModelSelected) return;
                e.preventDefault();
                handleSubmit();
              }
            }}
            onChange={(e) => setLocalPrompt(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" width="100%" pb={0.9}>
            <Box display="flex" alignItems="center">
              <Controller
                name={`cards.${cardIndex}.isSynced`}
                control={control}
                render={() => (
                  <Checkbox
                    disabled={syncAllChatsEnabled}
                    onChange={(e) =>
                      update(cardIndex, { ...fields[cardIndex], isSynced: e.target.checked })
                    }
                    checked={syncAllChatsEnabled || isSynced}
                  />
                )}
              />
              <Typography fontSize={12}>{strings.singleCardSyncChatCheckboxText}</Typography>
            </Box>
            <IconButton
              color="primary"
              sx={{ p: 1 }}
              aria-label={strings.singleCardPromptSubmitLabel}
              onClick={handleSubmit}
              disabled={!localPrompt || !isModelSelected}
            >
              <Send sx={{ color: colorsTheme.text.neutral }} fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </PromptInputWrapper>
    </AnswerModelCardWrapper>
  );
};

export default AnswersModelWindow;
