import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { ShareType } from 'const/constants';
interface IncreaseSharesProps {
  id: string;
  type: ShareType;
}
export interface SendEmailProps extends IncreaseSharesProps {
  recipients: string[];
  link: string;
}

const SendEmail = async ({ recipients, id, link, type }: SendEmailProps) => {
  const response = await axiosApi.post<{ response: string }>(routes.SEND_EMAIL({ id, type }), {
    recipients,
    link,
  });
  return response.data;
};

const IncreaseShares = async ({ id, type }: IncreaseSharesProps) => {
  const response = await axiosApi.post(routes.INCREASE_SHARES({ id, type }));

  return response.data;
};

export { SendEmail, IncreaseShares };
