import { useMemo, useState } from 'react';
import { Box, Typography, Autocomplete, Checkbox, PopperProps } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox, KeyboardArrowDown, Memory } from '@mui/icons-material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { calculateCheckedModels } from 'utils/MultimetricForm/formUtils';

import { ModelsInterface } from 'interfaces';
import {
  CustomPopper,
  StyledLabelWrapper,
  StyledModelOption,
  StyledModelPickerInput,
  StyledPaperComponent,
} from './styled';
import stringsJSON from 'assets/strings/strings.json';
interface Props {
  models: ModelsInterface[];
  fieldName?: string;
}

const strings = stringsJSON.general;

const ModelPickerAutocomplete = ({ models, fieldName = 'modelPicker' }: Props) => {
  const { control, setValue } = useFormContext();

  const modelsPickerState = useWatch({
    control,
    name: fieldName,
  });

  const [inputValue, setInputValue] = useState('');

  const totalCheckedModels = calculateCheckedModels(modelsPickerState);

  const getLabel = () => (
    <StyledLabelWrapper>
      <Memory sx={{ fontSize: 16 }} color="inherit" />
      <Typography fontSize={12}>
        {strings.modelPickerPlaceholder} {totalCheckedModels > 0 && `(${totalCheckedModels})`}
      </Typography>
    </StyledLabelWrapper>
  );

  const PopperComponent = useMemo(() => {
    return function MemoizedPopper(props: PopperProps) {
      return (
        <CustomPopper
          {...props}
          popperOptions={{
            modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
          }}
        />
      );
    };
  }, []);

  if (!models) {
    return null;
  }

  return (
    <Controller
      name="modelPicker"
      control={control}
      render={() => (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="models-picker"
          options={models}
          noOptionsText={strings.modelPickerNoModelsFoundText}
          value={models.filter((opt) => modelsPickerState[opt.id]?.checked)}
          onChange={(_, selectedOptions) => {
            models.forEach((opt) => {
              setValue(`${fieldName}.${opt.id}`, { checked: selectedOptions.includes(opt) });
            });
          }}
          onBlur={() => setInputValue('')}
          onInputChange={(_, newInputValue, reason) => {
            if (reason === 'input') {
              setInputValue(newInputValue);
            }
          }}
          inputValue={inputValue}
          getOptionLabel={(option) => option.id}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) => {
              const searchValue = inputValue.toLowerCase();
              return option.title.toLowerCase().includes(searchValue);
            })
          }
          renderOption={(props, option) => {
            const { key, ...modelProps } = props;
            return (
              <StyledModelOption key={key} {...modelProps}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  checked={modelsPickerState[option.id]?.checked || false}
                />
                <Box display="flex" gap={1.5}>
                  <img
                    src={
                      option.iconSrc.startsWith('http')
                        ? option.iconSrc
                        : `http://${option.iconSrc}`
                    }
                    alt={option.title}
                    height="18"
                    width="18"
                  />
                  <Typography lineHeight={1.5} fontSize={12}>
                    {option.title}
                  </Typography>
                </Box>
              </StyledModelOption>
            );
          }}
          PopperComponent={PopperComponent}
          PaperComponent={StyledPaperComponent}
          style={{ maxWidth: 368, width: '100%', minWidth: 129 }}
          renderInput={(params) => (
            <StyledModelPickerInput
              {...params}
              fullWidth
              label={getLabel()}
              placeholder={strings.modelPickerSearchPlaceholder}
            />
          )}
          renderTags={() => null}
          popupIcon={<KeyboardArrowDown fontSize="small" />}
          clearIcon={null}
          disablePortal
        />
      )}
    />
  );
};

export default ModelPickerAutocomplete;
