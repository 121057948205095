import { Box, CircularProgress, styled } from '@mui/material';
import theme from 'themes/theme';

interface Props {
  testId?: string;
  wrapperMinHeight?: number;
}

const LoadingWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const LoadingState = ({ testId, wrapperMinHeight = 60 }: Props) => {
  return (
    <LoadingWrapper minHeight={theme.spacing(wrapperMinHeight)}>
      <CircularProgress aria-label="Loading in progress" data-testid={testId} />
    </LoadingWrapper>
  );
};

export default LoadingState;
