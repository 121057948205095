import styled from '@emotion/styled';
import { Box, ListItemText } from '@mui/material';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';
import Select from '@mui/material/Select';

export const AnswerModelCardWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  minWidth: theme.spacing(35),
  height: theme.spacing(98),
  backgroundColor: colorsTheme.background.color2,
  border: `1px solid ${colorsTheme.border.main}`,
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: `${theme.spacing(1.5)}`,
});

export const HeaderModelCard = styled(Box)({
  backgroundColor: colorsTheme.background.color1,
  borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
  borderBottom: `1px solid ${colorsTheme.border.main}`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  width: '100%',
});

export const HeaderIconsWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const PromptInputWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  height: theme.spacing(18.75),
  padding: theme.spacing(2.8),
  width: '100%',
});

export const StyledModelDropdown = styled(Select)({
  height: 40,
  borderRadius: theme.spacing(1),
  width: theme.spacing(38.625),
  minWidth: theme.spacing(10),
  padding: `${theme.spacing(0.375)} ${theme.spacing(1)}`,
  boxShadow: shadowTheme.input,
  backgroundColor: colorsTheme.background.color1,
  border: `1px solid ${colorsTheme.drawer.primary}`,
  cursor: 'pointer',
});

export const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: 12,
    textWrap: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
});
