import { useState } from 'react';
import { Box, AppBar, Toolbar, Typography, Divider, IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { DesktopDrawerContainer, MobileDrawerContainer, StyledNavLink } from './styled';
import Logo from 'assets/logo.svg';
import { Link, useMatch } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import { colorsTheme } from 'themes/theme';
import { RoutesVars } from 'const/constRoutes';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.navbar;

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isComparePageActive = useMatch('/compare/*');

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar
        sx={{
          display: 'flex',
          mt: 1,
          flexDirection: 'row',
          justifyContent: { xs: 'space-between' },
        }}
      >
        <Box display="flex">
          <Link to="/">
            <Box sx={visuallyHidden}>Go to homepage</Box>
            <img src={Logo} alt={strings.logoAltText} />
          </Link>
        </Box>
        <Box gap={{ md: 3 }} display="flex" flexDirection="row">
          <Box component="div" display="flex" alignItems="center" order="0">
            <IconButton
              aria-expanded={mobileOpen}
              color="inherit"
              aria-label={strings.burgerBtnAriaLabel}
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ display: { md: 'none', sm: 'flex' } }}
            >
              <MenuRoundedIcon />
            </IconButton>
            <Typography
              variant="h4"
              component="div"
              display={{ xs: 'none', md: 'block' }}
              color={colorsTheme.text.dark}
            >
              {strings.navTitle}
            </Typography>
          </Box>
          <Box>
            <DesktopDrawerContainer variant="permanent">
              <StyledNavLink to={RoutesVars.HOME}>
                <HomeOutlinedIcon fontSize="small" />
                <span>{strings.homeLinkText}</span>
              </StyledNavLink>
              <StyledNavLink
                to={RoutesVars.COMPARE_MULTI_METRICS}
                className={isComparePageActive ? 'active' : ''}
              >
                <CompareArrowsOutlinedIcon fontSize="small" />
                <span>{strings.compareLinkText}</span>
              </StyledNavLink>
              <StyledNavLink to={RoutesVars.PLAYGROUND}>
                <GridViewRoundedIcon fontSize="small" />
                <span>{strings.playgroundLinkText}</span>
              </StyledNavLink>
            </DesktopDrawerContainer>
            <MobileDrawerContainer
              variant="temporary"
              anchor="right"
              onClose={() => setMobileOpen(false)}
              open={mobileOpen}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <StyledNavLink to={RoutesVars.HOME}>
                <HomeOutlinedIcon fontSize="small" />
                <span>{strings.homeLinkText}</span>
              </StyledNavLink>{' '}
              <StyledNavLink to={RoutesVars.COMPARE_MULTI_METRICS}>
                <CompareArrowsOutlinedIcon fontSize="small" />
                <span>{strings.multiMetricComparisonLinkText}</span>
              </StyledNavLink>
              <StyledNavLink to={RoutesVars.COMPARE_COST_OPTIMIZATION}>
                <CompareArrowsOutlinedIcon fontSize="small" />
                <span>{strings.costOptimizationLinkText}</span>
              </StyledNavLink>
              <StyledNavLink to={RoutesVars.PLAYGROUND}>
                <GridViewRoundedIcon fontSize="small" />
                <span>{strings.playgroundLinkText}</span>
              </StyledNavLink>
            </MobileDrawerContainer>
          </Box>
        </Box>
      </Toolbar>
      <Divider orientation="horizontal" />
    </AppBar>
  );
};

export default Navbar;
