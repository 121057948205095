import { useMemo } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

import DefaultLayout from 'layouts/DefaultLayout';
import { RoutesVars } from 'const/constRoutes';
import HomePage from 'pages/HomePage';
import PlaygroundPage from 'pages/PlaygroundPage';
import CompareCostOptimizationPage from 'pages/CompareCostOptimizationPage';
import CompareMultiMetricsPage from 'pages/CompareMultiMetricsPage';
import AllModelsPage from 'pages/AllModelsPage';
import AllComparisonsPage from 'pages/AllComparisonsPage';
import AppContextProvider from 'context/AppContextProvider';
import PrivateRoute from 'components/PrivateRoute';
import AuthContextProvider from 'context/Auth/AuthContextProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (!(error instanceof AxiosError) || query.meta?.hideDefaultToast) return;

      const message = query.meta?.errorMessage as string;
      if (message) {
        toast.error(message);
      } else if (query.state.data === undefined) {
        toast.error(`Ooops something went wrong: ${error.message}`);
      }
    },
  }),
});

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function App() {
  const MissingRoute = () => <Navigate to={{ pathname: RoutesVars.HOME }} replace />;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <DefaultLayout />
          </PrivateRoute>
        }
      >
        <Route path={RoutesVars.HOME} element={<HomePage />} />
        <Route path={RoutesVars.ALL_MODElS} element={<AllModelsPage />} />
        <Route path={RoutesVars.ALL_COMPARISONS} element={<AllComparisonsPage />} />
        <Route path={RoutesVars.COMPARE_MULTI_METRICS} element={<CompareMultiMetricsPage />} />
        <Route
          path={RoutesVars.COMPARE_COST_OPTIMIZATION}
          element={<CompareCostOptimizationPage />}
        />
        <Route path={RoutesVars.PLAYGROUND} element={<PlaygroundPage />} />
        <Route path="*" element={<MissingRoute />} />
      </Route>
    )
  );

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <AppContextProvider>
            <RouterProvider router={router} />
          </AppContextProvider>
        </AuthContextProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </div>
  );
}

export default App;
