import { Box, Card, Typography } from '@mui/material';

import { BoxWithGradient } from './BoxWithGradient';
import SearchInput from '../components/SearchInput';
import stringsJSON from 'assets/strings/strings.json';
import theme from 'themes/theme';

interface Props {
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SearchBanner = ({ setSearchText }: Props) => {
  const { greeting, subGreeting } = stringsJSON.homePage;

  return (
    <Card sx={{ position: 'relative', mt: 6, mb: 5, borderRadius: 4.5 }}>
      <BoxWithGradient mainColor="rgba(120,86,255, 0.3)" positionHorizontal="41%" />
      <BoxWithGradient mainColor="rgba(56,109,255,0.4)" positionHorizontal="55%" />

      <Box
        zIndex={2}
        position="relative"
        display="flex"
        alignItems="center"
        flexDirection="column"
        py={3}
        px={2}
        sx={{ textAlign: 'center', [theme.breakpoints.up('md')]: { py: 6, px: 7 } }}
      >
        <Typography component="h1" variant="h1" mb={1}>
          {greeting}
        </Typography>
        <Typography mb={3} component="p">
          {subGreeting}
        </Typography>
        <Box sx={{ [theme.breakpoints.up('md')]: { minWidth: 400 } }}>
          <SearchInput setSearchText={setSearchText} />
        </Box>
      </Box>
    </Card>
  );
};

export default SearchBanner;
