import { useMutation } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import SendLlmQuery from 'api/services/LlmQueryService';

const useMutateLlmQuery = () => {
  const { mutate, isSuccess, isPending, isError, data, mutateAsync } = useMutation({
    mutationKey: [QueryKeys.LLM_QUERY],
    mutationFn: SendLlmQuery,
    onSuccess(data) {
      return data;
    },
  });

  return {
    sendQuery: mutate,
    mutateAsync,
    isSuccess,
    responseData: data,
    isLoading: isPending,
    isError,
  };
};

export default useMutateLlmQuery;
