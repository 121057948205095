export enum ComparisonTypes {
  MULTI_METRICS = 'Multi Metric Comparison',
  COST_OPTIMIZATION = 'Cost Optimization & Estimation',
}

export const appURL = process.env.REACT_APP_URL as string;

export enum PopularComparison {
  COMPARISON_SEARCH_RESULT_LIMIT = 20,
  DEFAULT_COMPARISON_COUNT = 3,
}

export enum ShareType {
  MODELS = 'models',
  COMPARISONS = 'comparisons',
}

export enum CostOptimizationFormValidation {
  MINIMUM_NUMBER_OF_SELECTED_MODELS = 1,
  MAXIMUM_NUMBER_OF_SELECTED_MODELS = 4,
  MINIMUM_INPUT_NUMBER = 1,
  MAXIMUM_INPUT_NUMBER = 2147483647,
  MAXIMUM_PROMPT_CHARACTERS = 4000,
}

export enum AllComparisonsPagination {
  DEFAULT_COMPARISONS_PER_PAGE = 10,
  DEFAULT_STARTING_PAGE = 1,
}
