import { useMutation } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { createComparison } from 'api/services/ComparisonService';

const useCreateComparison = () => {
  const { mutate } = useMutation({
    mutationKey: [QueryKeys.CREATE_COMPARISON_DATA],
    mutationFn: createComparison,
  });

  return {
    mutate,
  };
};

export default useCreateComparison;
