import { AllComparisonsPagination } from 'const/constants';
import { ComparisonQueryParams } from './services/ComparisonService';

export const apiUrl: string = process.env.REACT_APP_API_URL as string;
const llmComparisonApiUrl = `${apiUrl}/llm-comparison`;
const llmPlaygroundApiUrl = `${apiUrl}/llm-playground`;

const routes = {
  MODELS: (count = '', searchQuery = '', ordering = ''): string => {
    const baseUrl = `${llmComparisonApiUrl}/models`;

    const params = new URLSearchParams({
      count,
      searchQuery,
      ordering,
    }).toString();

    const hasParams = count || searchQuery || ordering;
    return hasParams ? `${baseUrl}?${params}` : baseUrl;
  },
  COMPARISON: ({
    searchQuery = '',
    ordering = '-popularity',
    id = '',
    page = AllComparisonsPagination.DEFAULT_STARTING_PAGE,
    perPage = AllComparisonsPagination.DEFAULT_COMPARISONS_PER_PAGE,
  }: ComparisonQueryParams): string => {
    const baseUrl = `${llmComparisonApiUrl}/comparisons`;

    if (id) return `${baseUrl}/${id}`;

    const params = new URLSearchParams({
      searchQuery,
      ordering,
      page: page.toString(),
      perPage: perPage.toString(),
    }).toString();

    const hasParams = perPage || searchQuery || ordering;

    return hasParams ? `${baseUrl}?${params}` : baseUrl;
  },
  CREATE_COMPARISON: () => `${llmComparisonApiUrl}/comparisons/`,
  MULTI_METRIC_COMPARISON: ({ themes = '', categories = '' }) => {
    const params = new URLSearchParams({
      ...(themes && { themes }),
      ...(categories && { categories }),
    }).toString();
    const baseUrl = `${llmComparisonApiUrl}/themes/`;

    return params ? `${baseUrl}?${params}` : baseUrl;
  },
  MULTI_METRIC_COMPARISON_TABLE: ({ id = '', models = '', criteria = '' }): string => {
    const params = new URLSearchParams({
      ...(models && { models }),
      ...(criteria && { criteria }),
    }).toString();
    const baseUrl = `${llmComparisonApiUrl}/categories/${id}`;

    return params ? `${baseUrl}?${params}` : baseUrl;
  },
  COST_OPTIMIZATION_SCENARIOS: (): string => {
    const baseUrl = `${llmComparisonApiUrl}/scenarios`;
    return baseUrl;
  },
  GET_COMPARISON_EXPORT_FILE: ({ comparisonId }: { comparisonId: string }): string =>
    `${llmComparisonApiUrl}/export-comparison/${comparisonId}`,
  GET_CONVERSATION_EXPORT_FILE: ({ conversationId }: { conversationId: string }): string =>
    `${llmPlaygroundApiUrl}/export-conversation/${conversationId}`,
  SEND_EMAIL: ({ type = 'models', id = '' }) => `${llmComparisonApiUrl}/${type}/${id}/share/`,
  INCREASE_SHARES: ({ id = '', type = '' }) => {
    const modelIdPresent = id ? `${id}/shares/increase/` : '';
    return `${llmComparisonApiUrl}/${type}/${modelIdPresent}`;
  },
  GET_INTEGRATED_MODELS: () => `${llmPlaygroundApiUrl}/llms`,
  LLM_QUERY: () => `${llmPlaygroundApiUrl}/llm_query/`,
};

export { routes };
export default apiUrl;
