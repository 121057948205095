import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import { MultimetricSearchOption } from 'interfaces/MultiMetricComparisonInterface';
import { CustomPopper, StyledModelOption } from './styled';

interface SingleSelectAutocompleteProps {
  options: MultimetricSearchOption[];
  inputValue: string;
  onChange: (option: MultimetricSearchOption | null) => void;
  onInputChange: (value: string) => void;
  getOptionLabel: (option: MultimetricSearchOption) => string;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const SingleSelectAutocomplete = ({
  options,
  inputValue,
  onChange,
  onInputChange,
  getOptionLabel,
  renderInput,
}: SingleSelectAutocompleteProps) => {
  return (
    <Autocomplete
      noOptionsText="No results found."
      options={options}
      forcePopupIcon={false}
      inputValue={inputValue}
      value={null}
      sx={{}}
      onChange={(_, newValue) => onChange(newValue)}
      onInputChange={(_, newValue) => onInputChange(newValue)}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      PopperComponent={(props) => (
        <CustomPopper
          {...props}
          popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }}
        />
      )}
      renderOption={(props, option) => (
        <StyledModelOption {...props} key={option.id}>
          {getOptionLabel(option)}
        </StyledModelOption>
      )}
      renderInput={renderInput}
      fullWidth
    />
  );
};

export default SingleSelectAutocomplete;
