import { IconButton } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { handleFileDownloadFromApi } from 'utils/handleFileDownloadFromApi';
import { slugifyString } from 'utils/slugifyString';
import useGetConversationExportFile from 'hooks/useGetConversationExportFile';

interface Props {
  conversationId: string;
  modelName?: string;
}

const DownloadConversationButton = ({ conversationId, modelName }: Props) => {
  const { refetch, isFetching } = useGetConversationExportFile({ conversationId });

  const isDisabled = !modelName || isFetching || !conversationId;
  // TODO: Replace if we decide on a date formatting library
  const formattedDate = new Intl.DateTimeFormat('en', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })
    .format(new Date())
    .replace(/\//g, '-');
  const filename = slugifyString(`${modelName}-conversation-history-${formattedDate}`);

  return (
    <IconButton
      sx={{ p: 0.75 }}
      disabled={isDisabled}
      onClick={() => {
        handleFileDownloadFromApi({ refetch, filename });
      }}
    >
      <UploadIcon sx={{ fontSize: 20 }} />
    </IconButton>
  );
};

export default DownloadConversationButton;
