import { Box, Divider, Typography } from '@mui/material';
import ToggleCompareType from 'components/ToggleCompareType';
import { useLocation } from 'react-router-dom';

import theme from 'themes/theme';

interface Props {
  title: string;
  description: string;
}

const ComparePageWelcomeSection = ({ title, description }: Props) => {
  const location = useLocation();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mt: 4.5,
          mb: 3.5,
          flexDirection: 'column',
          alignItems: 'flex-start',
          [theme.breakpoints.up('md')]: { mt: 8, mb: 5 },
          [theme.breakpoints.up('lg')]: { flexDirection: 'row' },
        }}
      >
        <Box maxWidth={720}>
          <Typography component="h1" variant="h1" mb={1}>
            {title}
          </Typography>
          <Typography component="p">{description}</Typography>
        </Box>
        <Box sx={{ mt: { md: 2, lg: 0 } }}>
          <ToggleCompareType compareType={location.pathname} />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default ComparePageWelcomeSection;
