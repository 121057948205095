import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';

export interface getConversationExportFileServiceParams {
  conversationId: string;
}

const getConversationExportFileService = async ({
  conversationId,
}: getConversationExportFileServiceParams) => {
  const response = await axiosApi.get<Blob>(
    routes.GET_CONVERSATION_EXPORT_FILE({ conversationId }),
    { responseType: 'blob' }
  );

  return response.data;
};

export { getConversationExportFileService };
