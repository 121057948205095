import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import {
  ComparisonQueryParams,
  getComparisonData,
  Comparison,
} from 'api/services/ComparisonService';

export const useComparison = <T extends ComparisonQueryParams>(
  props: T
): UseQueryResult<Comparison<T>> & {
  comparisonData?: Comparison<T>;
} => {
  const result = useQuery<Comparison<T>>({
    queryKey: [QueryKeys.COMPARISON_DATA, props],
    queryFn: () => getComparisonData(props),
    refetchOnWindowFocus: false,
  });

  return {
    ...result,
    comparisonData: result.data,
  };
};

export default useComparison;
