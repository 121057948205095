import GradientBorderWrapper from 'components/GradientBorderWrapper';
import { Send } from '@mui/icons-material';
import { colorsTheme } from 'themes/theme';
import { Box, IconButton } from '@mui/material';
import SingleSelectAutocomplete from 'components/FormFields/SingleSelectAutocomplete/SingleSelectAutocomplete';
import { StyledModelPickerInput } from 'pages/CompareMultiMetricsPage/components/FilterFields/SearchInput/styled';
import { useCostOptimizationSearchInput } from './useCostOptimizationSearchInput';
import stringsJSON from 'assets/strings/strings.json';

const strings = stringsJSON;

const SearchInput = () => {
  const { allOptions, inputValue, handleInputChange, handleOptionSelect, getOptionLabel } =
    useCostOptimizationSearchInput();

  return (
    <GradientBorderWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={38}>
        <SingleSelectAutocomplete
          options={allOptions}
          inputValue={inputValue}
          onChange={handleOptionSelect}
          onInputChange={handleInputChange}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <StyledModelPickerInput
              {...params}
              placeholder={strings.general.searchInputPlaceholder}
            />
          )}
        />
        <IconButton
          color="primary"
          sx={{ p: 1 }}
          aria-label={strings.costOptimizationPage.searchSubmitButtonAriaLabel}
          type="submit"
        >
          <Send sx={{ color: colorsTheme.text.neutral }} fontSize="small" />
        </IconButton>
      </Box>
    </GradientBorderWrapper>
  );
};

export default SearchInput;
