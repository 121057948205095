import { createContext } from 'react';

import { ReducerAction } from './AppReducer';
import { Categories, Criteria, ThemesInterface } from 'interfaces/MultiMetricComparisonInterface';
import ModelsInterface from 'interfaces/ModelsInterface';

export interface AppStateInterface {
  application: {
    themes: ThemesInterface[];
    categories: Categories[];
    criteria: Criteria[];
    models: ModelsInterface[];
  };
}

export interface AppContextInterface {
  state: AppStateInterface;
  dispatch: React.Dispatch<ReducerAction>;
}

export const appInitialState: AppStateInterface = {
  application: {
    themes: [{ id: '', name: '', categories: [] }],
    categories: [{ id: '', name: '', description: null, iconSrc: '', criteria: [] }],
    criteria: [{ id: '', name: '' }],
    models: [{ id: '', title: '', description: 'null', iconSrc: '', likes: 0, shares: 0 }],
  },
};

const initialContextState = {
  state: appInitialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
};

export const AppContext = createContext<AppContextInterface>(initialContextState);
AppContext.displayName = 'AppContext';
