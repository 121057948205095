import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { getIntegratedModels } from 'api/services/GetIntegratedModels';

const useGetIntegratedModels = () => {
  const {
    data: integratedModelsData,
    isLoading,
    isError,
    error: modelsError,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.MODELS_DATA],
    queryFn: () => getIntegratedModels(),
    refetchOnWindowFocus: false,
  });

  return {
    integratedModelsData,
    isError,
    isLoading,
    modelsError,
    isFetched,
    refetch,
  };
};

export default useGetIntegratedModels;
