import { Box, InputBase } from '@mui/material';
import { useFormContext, RegisterOptions, Controller } from 'react-hook-form';
import { colorsTheme, shadowTheme } from 'themes/theme';

interface Props {
  inputName: string;
  ariaLabel: string;
  placeholder: string;
}

const NumberInput = ({ inputName, ariaLabel, placeholder }: Props) => {
  const { control } = useFormContext();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = event.target.value;
    // Allow empty string or positive integers
    if (value === '' || /^\d+$/.test(value)) {
      onChange(value);
    }
  };

  const registerOptions: RegisterOptions = {
    validate: (value) => {
      if (value === '') return true; // Allow empty string
      const numValue = parseFloat(value);
      return (
        (!isNaN(numValue) && numValue >= 0) || 'Please enter a positive number or leave it empty'
      );
    },
  };

  return (
    <Box
      sx={{
        border: `1px solid ${colorsTheme.background.color3}`,
        borderRadius: 2,
        backgroundColor: colorsTheme.background.color1,
        boxShadow: shadowTheme.input,
      }}
    >
      <Controller
        name={inputName}
        control={control}
        rules={registerOptions}
        render={({ field: { onChange, value, ref } }) => (
          <InputBase
            type="text"
            inputMode="decimal"
            value={value}
            onChange={(e) => handleChange(e, onChange)}
            sx={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              opacity: 1,
              fontSize: 12,
              minHeight: 38,
              px: 1,
              flex: 1,
              '& ::placeholder': {
                fontSize: 12,
                whiteSpace: 'nowrap',
                color: colorsTheme.text.neutral,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                opacity: 1,
              },
            }}
            placeholder={placeholder}
            inputProps={{
              'aria-label': ariaLabel,
              ref: ref,
            }}
          />
        )}
      />
    </Box>
  );
};

export default NumberInput;
