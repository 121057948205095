import { Box, Typography, styled } from '@mui/material';

import theme from 'themes/theme';

interface Props {
  children?: string;
  testId?: string;
  text?: string;
}

const NoResultsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: theme.spacing(60),
  width: '100%',
});

const NoResultFoundState = ({ children, testId, text }: Props) => {
  return (
    <NoResultsWrapper>
      {children ? (
        children
      ) : (
        <Typography aria-live="polite" role="status" data-testid={testId}>
          {text || 'No results found'}
        </Typography>
      )}
    </NoResultsWrapper>
  );
};

export default NoResultFoundState;
