import { Button, Card, styled } from '@mui/material';
import theme from 'themes/theme';

export const CardWrapper = styled(Card)({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  height: '100%',
});

export const ShareButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  '&:hover': {
    background: 'none',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
});
