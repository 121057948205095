import { Backdrop, Box, Divider, Fade, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import PeopleIcon from '@mui/icons-material/People';
import {
  ModalContent,
  ModalToolbar,
  CopyLinkWrapper,
  ContentBox,
  InputWrapper,
  StyledInputBase,
  ShareButton,
  FooterWrapper,
  CopyLinkButton,
} from './helpers/styled';
import theme, { colorsTheme } from 'themes/theme';
import { useEffect, useState } from 'react';
import useShareModel, { capitalizeFirstLetter } from 'hooks/useShare';
import { FormSchema, useShareModelForm } from './helpers/useShareModelForm';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import { appURL, ShareType } from 'const/constants';
import stringsJSON from 'assets/strings/strings.json';
interface Props {
  isOpen: boolean;
  id: string;
  isComparison?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const strings = stringsJSON.shareModal;

const ShareModel = ({ isOpen, setIsOpen, id, isComparison = false }: Props) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const queryClient = useQueryClient();
  const shareType = isComparison ? ShareType.COMPARISONS : ShareType.MODELS;
  const { sendEmail, increaseShares, isEmailSuccess, isSharesSuccess } = useShareModel();
  const { register, handleSubmit, reset, isValid } = useShareModelForm();

  const buildShareLink = () => {
    const basePath = `${appURL}/compare/`;
    const path = isComparison
      ? `cost-optimization?comparisonId=${id}`
      : `multi-metrics?modelId=${id}`;
    return `${basePath}${path}`;
  };

  const handleClose = () => {
    setLinkCopied(false);
    setIsOpen(false);
  };

  const handleCopyLink = () => {
    if (!linkCopied) {
      navigator.clipboard.writeText(buildShareLink());
      setLinkCopied(true);
      increaseShares({
        id,
        type: shareType,
      });
    }
  };

  const onSendEmail = (data: FormSchema) => {
    sendEmail({
      id,
      recipients: [data.email],
      link: buildShareLink(),
      type: shareType,
    });
    reset();
    handleClose();
  };

  const currentDateFormatted = () => {
    return new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  useEffect(() => {
    if (isEmailSuccess || isSharesSuccess) {
      const queryKey = isComparison ? QueryKeys.COMPARISON_DATA : QueryKeys.MODELS_DATA;
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    }
  }, [isEmailSuccess, isSharesSuccess, isComparison, queryClient]);

  return (
    <Modal
      aria-labelledby="modal-window"
      open={isOpen}
      closeAfterTransition
      onClose={handleClose}
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={isOpen}>
        <ModalContent id="modal-window">
          <ModalToolbar>
            <Typography
              component="h2"
              variant="h4"
              color={colorsTheme.text.dark}
              sx={{ [theme.breakpoints.up('md')]: { fontSize: 18 } }}
            >
              {strings.shareText} {capitalizeFirstLetter(shareType.slice(0, -1))}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ gap: 0.6, [theme.breakpoints.up('md')]: { gap: 2 } }}
            >
              <CopyLinkWrapper onClick={handleCopyLink}>
                <LinkIcon />
                <CopyLinkButton component="button">
                  {linkCopied ? strings.linkCopiedText : strings.copyText}
                </CopyLinkButton>
              </CopyLinkWrapper>
              <IconButton onClick={handleClose} sx={{ p: 0.5 }}>
                <CloseIcon sx={{ fontSize: 24, [theme.breakpoints.up('md')]: { fontSize: 28 } }} />
              </IconButton>
            </Box>
          </ModalToolbar>
          <Divider orientation="horizontal" />
          <ContentBox>
            <InputWrapper component="form" onSubmit={handleSubmit(onSendEmail)}>
              <StyledInputBase
                placeholder={strings.shareEmailPlaceholder}
                aria-label={strings.shareEmailLabel}
                {...register('email', {
                  setValueAs: (value: string) => value.trim(),
                  required: true,
                })}
              />
              <ShareButton
                aria-label={strings.shareSubmitButtonLabel}
                type="submit"
                disabled={!isValid}
              >
                {strings.shareText}
              </ShareButton>
            </InputWrapper>
            <FooterWrapper>
              <PeopleIcon fontSize="small" />
              <Typography
                fontStyle="italic"
                sx={{
                  opacity: 0.8,
                  fontSize: 12,
                  [theme.breakpoints.up('md')]: {
                    fontSize: 14,
                  },
                }}
              >
                {strings.datestampPrefix} {shareType.slice(0, -1)} {strings.datestampPrefixPart2}
                {currentDateFormatted()}.
              </Typography>
            </FooterWrapper>
          </ContentBox>
        </ModalContent>
      </Fade>
    </Modal>
  );
};

export default ShareModel;
