import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';

export interface LlmQueryProps {
  modelId: string;
  prompt: string;
  conversationId: string | null;
}

export interface SingleLlmMessage {
  id: string;
  type: 'ai' | 'human';
  message: string;
}
export interface LlmQueryResponse {
  conversationId: string;
  modelId: string;
  conversation: SingleLlmMessage[];
}

const SendLlmQuery = async ({ modelId, conversationId, prompt }: LlmQueryProps) => {
  const response = await axiosApi.post<LlmQueryResponse>(routes.LLM_QUERY(), {
    modelId,
    prompt,
    conversationId,
  });
  return response.data;
};

export default SendLlmQuery;
