import { Chip, Typography, styled } from '@mui/material';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  filter: [string, string];
  handleDelete: () => void;
  isLoading: boolean;
  isRefetching: boolean;
}

const StyledChip = styled(Chip)({
  minWidth: 'fit-content',
  borderRadius: theme.spacing(1),
  paddingRight: theme.spacing(0.75),
  backgroundColor: colorsTheme.background.color3,
  boxShadow: shadowTheme.pill,
  '& .MuiChip-deleteIcon': {
    color: `${colorsTheme.text.dark} !important`,
    fontSize: 14,
    marginTop: theme.spacing(0.25),
  },
});

const FilterChip = ({ filter, handleDelete, isLoading, isRefetching }: Props) => {
  const [filterType, filterValue] = filter;

  const label = (
    <Typography sx={{ textTransform: 'capitalize', color: colorsTheme.text.dark, fontSize: 12 }}>
      {filterType}: <strong>{filterValue}</strong>
    </Typography>
  );

  return (
    <StyledChip
      disabled={isLoading || isRefetching}
      label={label}
      deleteIcon={<ClearIcon sx={{ strokeWidth: 0.8, stroke: colorsTheme.text.dark }} />}
      onDelete={handleDelete}
    />
  );
};

export default FilterChip;
