import PopularModelsSectionHomepage from './PopularModelsSection';
import SearchBanner from './SearchBanner';
import PopularComparisonsSection from '../../components/PopularComparisonSection';
import { useState } from 'react';
import ShareModel from 'components/ShareModal';

const HomePage = () => {
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [sharedItemId, setSharedItemId] = useState('');
  const [isComparison, setIsComparison] = useState(false);

  const handleShareModel = (id: string) => {
    setSharedItemId(id);
    setIsComparison(false);
    setIsShareModalOpen(true);
  };

  const handleShareComparison = (id: string) => {
    setSharedItemId(id);
    setIsComparison(true);
    setIsShareModalOpen(true);
  };

  return (
    <div>
      <ShareModel
        isOpen={isShareModalOpen}
        setIsOpen={setIsShareModalOpen}
        id={sharedItemId}
        isComparison={isComparison}
      />
      <SearchBanner setSearchText={setSearchText} />
      <PopularModelsSectionHomepage searchText={searchText} handleShareModel={handleShareModel} />
      <PopularComparisonsSection
        searchText={searchText}
        handleShareComparison={handleShareComparison}
      />
    </div>
  );
};

export default HomePage;
