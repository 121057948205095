import { FormControl, Select, MenuItem, ListItemText, SelectChangeEvent, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import useCostOptimizationScenarios from 'hooks/useCostOptimizationScenarios';
import { colorsTheme, shadowTheme } from 'themes/theme';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PlaygroundFormSchema } from '../../CostOptimizationFormWrapper';
import CustomSelectInput from 'components/FormFields/CustomSelectInput';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.costOptimizationPage;

const ScenariosPickerFilter = () => {
  const { control } = useFormContext<PlaygroundFormSchema>();
  const { costOptimizationScenariosData } = useCostOptimizationScenarios();

  const MenuProps = {
    PaperProps: {
      style: { boxShadow: shadowTheme.popper, marginTop: 5 },
    },
  };

  return (
    <Controller
      name="scenarioPicker"
      control={control}
      render={({ field }) => (
        <FormControl fullWidth sx={{ '.MuiListItemText-root': { ml: 0.5, mt: 0.5 } }}>
          <Select
            {...field}
            id="scenario-select"
            label={strings.scenarioInputText}
            input={<CustomSelectInput />}
            renderValue={(value) => {
              const scenario = costOptimizationScenariosData?.find((item) => item.id === value);
              const isOptionSelected = scenario?.name;
              const text = isOptionSelected ? scenario.name : strings.scenarioInputText;

              return (
                <Box
                  fontSize={12}
                  ml={0.25}
                  color={isOptionSelected ? 'inherit' : colorsTheme.text.neutral}
                >
                  {text}
                </Box>
              );
            }}
            IconComponent={(props) => (
              <KeyboardArrowDown
                {...props}
                fontSize="small"
                htmlColor={colorsTheme.border.color4}
              />
            )}
            sx={{
              minHeight: 40,
              maxHeight: 40,
              borderRadius: 2,
              px: 1,
              boxShadow: shadowTheme.input,
              backgroundColor: colorsTheme.background.color1,
              py: 0.375,
              border: `1px solid ${colorsTheme.drawer.primary}`,
              cursor: 'pointer',
            }}
            displayEmpty
            MenuProps={MenuProps}
            onChange={(e: SelectChangeEvent<string>) => {
              field.onChange(e.target.value);
            }}
          >
            {costOptimizationScenariosData &&
              costOptimizationScenariosData.map((scenario) => (
                <MenuItem
                  key={scenario.id}
                  value={scenario.id}
                  sx={{
                    py: 1.5,
                    px: 2,
                    '&.Mui-selected': {
                      background: 'transparent',
                    },
                    '&:hover, &:focus, &.Mui-selected:focus, &.Mui-selected:hover': {
                      backgroundColor: colorsTheme.background.optionHover,
                    },
                  }}
                >
                  <ListItemText primary={scenario.name} sx={{ '& span': { fontSize: 12 } }} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default ScenariosPickerFilter;
