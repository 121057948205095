import { styled } from '@mui/material';
import { Box, Button, IconButton } from '@mui/material';
import theme, { colorsTheme } from 'themes/theme';
import UploadIcon from '@mui/icons-material/Upload';

export const ComparisonCardWrapper = styled(Box)({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `${theme.spacing(0.125)} solid ${colorsTheme.border.main}`,
  borderRadius: theme.spacing(1.5),
  scrollSnapAlign: 'start',
  maxHeight: theme.spacing(110.375),

  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
});

export const HeadingWrapper = styled(Box)({
  display: 'flex',
  backgroundColor: colorsTheme.drawer.secondary,
  height: theme.spacing(8),
  width: '100%',
  padding: theme.spacing(1.5, 2.5),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
  borderBottom: `${theme.spacing(0.125)} solid ${colorsTheme.border.main}`,
});

export const FooterWrapper = styled(Box)({
  backgroundColor: colorsTheme.drawer.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  padding: theme.spacing(1.5, 2.5),
  flexDirection: 'row',
  height: theme.spacing(8),
  borderRadius: `0 0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  borderTop: `${theme.spacing(0.125)} solid ${colorsTheme.border.main}`,
});

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gridTemplateColumns: '1fr',
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  backgroundColor: colorsTheme.background.color2,
  scrollSnapType: 'x mandatory',
  WebkitOverflowScrolling: 'touch',

  [theme.breakpoints.up('md')]: {
    flexWrap: 'wrap',

    '&:has(> :only-child)': {
      justifyContent: 'center',
    },
  },
}));

export const HeadingBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  height: '100%',
});

export const CompareButton = styled(Button)({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(0.25, 2),
    width: theme.spacing(16.125),
    height: theme.spacing(4),
    display: 'flex',
  },
});

export const StyledUploadIcon = styled(UploadIcon)({
  width: theme.spacing(2.5),
  height: theme.spacing(3),
  color: colorsTheme.icon.primary,
});

export const FooterButton = styled(IconButton)({
  padding: `0 !important`,
  '&:hover': {
    background: 'none',
    '& svg': {
      color: colorsTheme.primary.main,
    },
  },
});
