import { Categories, ThemesInterface } from 'interfaces/MultiMetricComparisonInterface';
import {
  CategoryPickerFormState,
  CriteriaFormState,
  ModelPickerFormState,
  ThemePickerFormState,
} from './types';
import { CostOptimizationScenariosInterface, ModelsInterface } from 'interfaces';

/**
 * Generates default form values based on categories.
 * @param categories - Array of categories
 * @returns Default form values
 */
export function generateDefaultCategoryValues(categories: Categories[]): CategoryPickerFormState {
  if (!categories) return {};
  return categories.reduce((acc, category) => {
    acc[category.id] = {
      checked: false,
      criteria: category.criteria.reduce((cAcc, criterion) => {
        cAcc[criterion.id] = false;
        return cAcc;
      }, {} as CriteriaFormState),
    };
    return acc;
  }, {} as CategoryPickerFormState);
}

/**
 * Generates default form values based on themes.
 * @param themes - Array of themes
 * @returns Default form values
 */
export function generateDefaultModelsValues(models: ModelsInterface[]): ModelPickerFormState {
  if (!models) return {};
  return models.reduce((acc, model) => {
    acc[model.id] = {
      checked: false,
    };
    return acc;
  }, {} as ThemePickerFormState);
}

/**
 * Generates default form values based on themes.
 * @param themes - Array of themes
 * @returns Default form values
 */
export function generateDefaultThemeValues(themes: ThemesInterface[]): ThemePickerFormState {
  if (!themes) return {};
  return themes.reduce((acc, theme) => {
    acc[theme.id] = {
      checked: false,
    };
    return acc;
  }, {} as ThemePickerFormState);
}

/**
 * Generates default form values based on costOptimizationScenarios.
 * @param costOptimizationScenarios - Array of costOptimizationScenarios
 * @returns Default form values for cost optimization scenarios
 */
export function generateDefaultCostOptimizationScenariosValues(
  costOptimizationScenarios: CostOptimizationScenariosInterface[]
): string {
  if (!costOptimizationScenarios) return '';
  return costOptimizationScenarios[0].id;
}

/**
 * Calculates the total number of categories that are fully checked or in an indeterminate state.
 * @param categoriesPicker - The form state of categoriesPicker
 * @returns The total number of categories checked or indeterminate
 */
export function calculateCheckedCategories(categoriesPicker: CategoryPickerFormState): number {
  if (Object.keys(categoriesPicker)[0] === '') return 0;

  return Object.values(categoriesPicker).reduce((total, category) => {
    const criteriaValues = Object.values(category.criteria);
    const allChecked = criteriaValues.every(Boolean);
    const anyChecked = criteriaValues.some(Boolean);

    if (allChecked || (anyChecked && !allChecked)) return total + 1;

    return total;
  }, 0);
}

/**
 * Calculates the total number of selected themes.
 * @param ThemePicker - The form state of ThemePicker
 * @returns The total number of selected themes
 */
export function calculateCheckedThemes(themePicker: ThemePickerFormState | undefined): number {
  if (!themePicker) {
    return 0;
  }
  return Object.values(themePicker).filter((theme) => theme.checked || theme.indeterminate).length;
}

/**
 * Calculates the total number of selected models.
 * @param ModelPickerFormState - The form state of ModelPicker
 * @returns The total number of selected models
 */
export function calculateCheckedModels(modelPicker: ModelPickerFormState): number {
  if (modelPicker && Object.keys(modelPicker)[0] === '') return 0;

  return (
    modelPicker &&
    Object.values(modelPicker).reduce((total, theme) => {
      if (theme.checked) return total + 1;

      return total;
    }, 0)
  );
}

export function updateThemeState(
  theme: ThemesInterface,
  categoriesPicker: CategoryPickerFormState
) {
  const categoryStates = theme.categories.map((cat) => categoriesPicker[cat.id]);

  const allChecked = categoryStates.every((cat) => cat?.checked);
  const someCheckedOrIndeterminate = categoryStates.some(
    (cat) => cat?.checked || cat?.indeterminate
  );

  return {
    checked: allChecked,
    indeterminate: !allChecked && someCheckedOrIndeterminate,
  };
}
