import { Box, styled } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useMemo } from 'react';

import { MultiMetricTableInterface } from 'interfaces';
import { colorsTheme } from 'themes/theme';
import NoResultFoundState from 'components/NoResultFoundState';

const StyledDataGrid = styled(DataGrid)({
  '& .super-app-theme--first--header': {
    backgroundColor: colorsTheme.primary.main,
    color: colorsTheme.text.light,
    borderRight: `1px solid ${colorsTheme.border.light}`,
    paddingLeft: 24,
  },
  '& .MuiDataGrid-columnSeparator': {
    color: `${colorsTheme.background.color2} !important`,
  },
  '& .MuiDataGrid-row': {
    backgroundColor: colorsTheme.background.color2,

    '&.super-app-theme--row-odd': {
      backgroundColor: colorsTheme.background.color3,
    },
  },
  '& .super-app-theme--header': {
    backgroundColor: colorsTheme.primary.main,
    color: colorsTheme.text.light,
  },
  '& .super-app-theme--row-first-cell': {
    borderRight: `1px solid ${colorsTheme.border.gray}`,
    fontWeight: 600,
    paddingLeft: 24,
  },
});

export const EmptyStateWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 490,
  width: 'auto',
});

const MultiMetricTable = ({ tableData }: { tableData: MultiMetricTableInterface[] }) => {
  const tableColumns: GridColDef[] = useMemo(() => {
    if (!tableData.length) return [];

    return (
      tableData &&
      tableData[0]?.columns.map((models, index) => ({
        field: models.columnId,
        headerName: models.name,
        sortable: false,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        minWidth: index === 0 ? 330 : 125,
        headerClassName: index === 0 ? 'super-app-theme--first--header' : 'super-app-theme--header',
        cellClassName: index === 0 ? 'super-app-theme--row-first-cell' : '',
      }))
    );
  }, [tableData]);

  const tableRowsMap = useMemo(() => {
    if (!tableData.length) return [];

    return tableData
      .slice(1)
      .map((rowData) =>
        Object.fromEntries(rowData?.columns.map((value) => [value.columnId, value.name]))
      );
  }, [tableData]);

  if (!tableData.length) return <NoResultFoundState text="No results to display." />;

  return (
    <Box sx={{ height: 'auto', width: 'auto' }}>
      <StyledDataGrid
        hideFooter
        disableColumnSorting
        disableColumnFilter
        disableColumnMenu
        rows={tableRowsMap}
        columns={tableColumns}
        rowHeight={52}
        columnHeaderHeight={48}
        sx={{ bgcolor: colorsTheme.background.color1, boxShadow: 0, border: 0, fontSize: 12 }}
        getRowClassName={({ indexRelativeToCurrentPage }) =>
          !!(indexRelativeToCurrentPage & 1) ? 'super-app-theme--row-odd' : ''
        }
      />
    </Box>
  );
};

export default MultiMetricTable;
