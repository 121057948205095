import { ArrowBack } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useQuery } from 'App';
import ErrorState from 'components/ErrorState';
import LoadingState from 'components/LoadingState';
import PaginationStack from 'components/PaginationStack';
import { SectionHeading } from 'components/SectionHeading';
import ShareModel from 'components/ShareModal';
import useComparison from 'hooks/useComparison';
import ComparisonCard from 'pages/HomePage/components/ComparisonCard';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const getPageToUse = (totalPages: number, queryPage: string | number): number => {
  return Math.max(1, Math.min(Number(queryPage), totalPages));
};

const AllComparisonsPage = () => {
  const queryPage = useQuery().get('page') || 1;

  const { isError, isLoading, refetch, comparisonData } = useComparison({
    ordering: 'title',
    page: queryPage,
  });

  const currentPage = getPageToUse(comparisonData?.totalPages || 0, queryPage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comparisonId, setComparisonId] = useState('');

  const handleShareComparison = (id: string) => {
    setIsModalOpen(true);
    setComparisonId(id);
  };

  const renderCards = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (isError) {
      return <ErrorState refetch={refetch} />;
    }

    if (!comparisonData?.comparisons?.length || !comparisonData) {
      return <ErrorState refetch={refetch} errorMessage="No comparisons found." />;
    }

    return comparisonData.comparisons.map((data) => {
      return (
        <ComparisonCard
          key={data.id}
          comparisonData={data}
          handleShareComparison={handleShareComparison}
        />
      );
    });
  };

  return (
    <Box width="100%" pt={6}>
      <ShareModel
        id={comparisonId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isComparison={true}
      />
      <NavLink to="/">
        <Button
          size="small"
          variant="text"
          sx={{ mb: 1.75 }}
          startIcon={<ArrowBack sx={{ width: 14 }} />}
        >
          Back to Home
        </Button>
      </NavLink>
      <SectionHeading headingText="All Comparisons" />
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>{renderCards()}</Box>
      {!isLoading && !isError && (
        <PaginationStack count={comparisonData?.totalPages || 0} page={currentPage} />
      )}
    </Box>
  );
};

export default AllComparisonsPage;
