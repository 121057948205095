import { FC, useReducer, useMemo, ReactNode } from 'react';

import { appInitialState, AppContext } from './AppContext';
import { appReducer } from './AppReducer';

interface Props {
  children: ReactNode;
}

const AppContextProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, appInitialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
