import {
  useForm,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import PlaygroundModelsField from '../FilterFields/PlaygroundModelsField';
import useGetIntegratedModels from 'hooks/useGetIntegratedModels';
import { AnswerWindowsSection } from 'pages/PlaygroundPage/styled';
import AnswersModelWindow from '../AnswersModelWindow';
import { Box } from '@mui/material';
import PromptWindow from '../PromptWindow';
import { GlobalPromptError, GlobalPromptResponse } from 'pages/PlaygroundPage';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PlaygroundFormErrors from '../PlaygroundFormErrors';

const conversationSchema = z.object({
  id: z.string(),
  type: z.union([z.literal('ai'), z.literal('human')]),
  message: z.string(),
});

const playgroundCardSchema = z.object({
  isSynced: z.boolean(),
  isEmpty: z.boolean(),
  modelId: z.string(),
  conversationId: z.string(),
  conversation: z.array(conversationSchema),
});

const formSchema = z.object({
  globalPrompt: z.string(),
  syncAllChats: z.boolean(),
  cards: z.array(playgroundCardSchema),
});

export type PlaygroundCardSchema = z.infer<typeof playgroundCardSchema>;
export type PlaygroundFormSchemaType = z.infer<typeof formSchema>;
export type PlaygroundFieldArray = UseFieldArrayReturn<PlaygroundFormSchemaType, 'cards', 'id'>;

type FormProps = {
  onSubmit: SubmitHandler<PlaygroundFormSchemaType>;
  globalLoading: boolean;
  globalError: GlobalPromptError[] | undefined;
  setGlobalError: React.Dispatch<React.SetStateAction<GlobalPromptError[] | undefined>>;
  responseGlobal: (GlobalPromptResponse | undefined)[];
};

export const emptyModelCard = {
  isSynced: true,
  isEmpty: true,
  conversationId: '',
  conversation: [],
};

const PlaygroundFormWrapper = ({
  onSubmit,
  globalLoading,
  responseGlobal,
  globalError,
  setGlobalError,
}: FormProps) => {
  const { integratedModelsData } = useGetIntegratedModels();
  const [showErrors, setShowErrors] = useState(false);
  const methods = useForm<PlaygroundFormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      globalPrompt: '',
      syncAllChats: true,
      cards: [...Array(2).fill({ ...emptyModelCard, modelId: uuidv4() })],
    },
  });
  const syncAllChatsEnabled = methods.watch('syncAllChats');

  const playgroundFieldsArray = useFieldArray({
    control: methods.control,
    name: 'cards',
  });

  useEffect(() => {
    if (!responseGlobal || responseGlobal.length < 1) return;

    const updatedFields = playgroundFieldsArray.fields.map((model) => {
      const cardModel = responseGlobal.find((card) => card?.modelId === model.modelId);
      if (cardModel) {
        return {
          ...model,
          conversationId: cardModel.conversationId,
          conversation: cardModel.history,
        };
      }
      return model;
    });

    playgroundFieldsArray.replace(updatedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseGlobal, methods]);

  if (!integratedModelsData) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          mb={3}
          display="flex"
          width="100%"
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent="center"
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <PlaygroundModelsField
            setShowErrors={setShowErrors}
            models={integratedModelsData}
            playgroundFieldsArray={playgroundFieldsArray}
          />
          <PlaygroundFormErrors showErrors={showErrors} />
        </Box>
        <PromptWindow
          playgroundFieldsArray={playgroundFieldsArray}
          onSubmit={onSubmit}
          modelsData={integratedModelsData}
          setShowErrors={setShowErrors}
        />
        <AnswerWindowsSection>
          {playgroundFieldsArray.fields.map(
            ({ id, modelId, conversation, conversationId }, index) => {
              const isError = globalError?.find(({ id }) => id === modelId)?.error || false;
              return (
                <AnswersModelWindow
                  globalError={isError}
                  setGlobalError={setGlobalError}
                  globalLoading={globalLoading}
                  key={id}
                  setShowErrors={setShowErrors}
                  syncAllChatsEnabled={syncAllChatsEnabled}
                  playgroundFieldsArray={playgroundFieldsArray}
                  modelId={modelId}
                  conversationData={conversation}
                  conversationId={conversationId}
                  cardIndex={index}
                />
              );
            }
          )}
        </AnswerWindowsSection>
      </form>
    </FormProvider>
  );
};

export default PlaygroundFormWrapper;
