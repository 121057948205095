import { Box, Typography } from '@mui/material';
import { SingleComparison } from 'interfaces/ComparisonInterface';
import ComparisonCard from 'pages/HomePage/components/ComparisonCard';

interface Props {
  selectedScenarioData: SingleComparison;
  handleShareComparison: (id: string) => void;
}

const SingleComparisonSection = ({ selectedScenarioData, handleShareComparison }: Props) => {
  return (
    <Box component="section" width="100%">
      <Box display="flex">
        <Typography variant="h3" component="h2" lineHeight={1.85}>
          Your Comparison
        </Typography>
      </Box>
      <Box>
        <ComparisonCard
          comparisonData={selectedScenarioData}
          handleShareComparison={handleShareComparison}
        />
      </Box>
    </Box>
  );
};

export default SingleComparisonSection;
