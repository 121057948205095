/* eslint-disable max-len */
import { createTheme } from '@mui/material/styles';

export const shadowTheme = {
  input: '0px 1px 3px 0px rgba(25, 33, 61, 0.10)',
  popper: '0px 8px 15px 0px rgba(0, 58, 140, 0.10)',
  pill: '0px 1px 3px 0px rgba(25, 33, 61, 0.10)',
  card: '0px 2px 4px 0px rgba(25, 33, 61, 0.08)',
};

export const colorsTheme = {
  primary: {
    light: '#2392ff14',
    main: '#2388FF',
  },
  secondary: {
    light: '#ba68c8',
    main: '#944DF6',
    dark: '#7b1fa2',
  },
  success: {
    light: '#4caf50',
    main: '#2e7d32',
    dark: '#1b5e20',
  },
  drawer: {
    primary: '#F0F2F5',
    secondary: '#FFFFFF',
  },
  text: {
    dark: '#19213D',
    neutral: '#666F8D',
    light: '#FFF',
    info: '#959DAC',
    total: '#2979FC',
    warning: '#ed6c02',
  },
  border: {
    main: '#E3E6EA',
    neutral: '#D7D7D7',
    color4: '#666F8D',
    gray: '#EBECEF',
    light: '#FFF',
  },
  icon: {
    primary: '#615E7C',
    neutral: '#D3D5DC',
  },
  background: {
    color1: '#FFF',
    color2: '#F7F8FA',
    color3: '#F0F2F5',
    color4: '#F6FAFF',
    accent: '#2388FF',
    linearGradient: '#174BD2',
    disabled: '#A0A0A0',
    optionHover: '#F2F4FD',
  },
  shades: {
    neutral: '#EBECEF',
  },
};

export const AppTheme = createTheme({
  typography: {
    fontFamily: 'Inter ,sans-serif',
    h1: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 22,
    },
    h2: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 20,
    },
    h3: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 18,
    },
    h4: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 16,
    },
    h5: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 14,
    },
    h6: {
      color: colorsTheme.text.dark,
      fontWeight: 500,
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
      color: colorsTheme.text.neutral,
    },
  },
  palette: {
    primary: {
      light: colorsTheme.primary.light,
      main: colorsTheme.primary.main,
    },
    secondary: {
      main: colorsTheme.secondary.main,
    },
    background: {
      default: colorsTheme.background.color2,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&::before': {
            backgroundColor: colorsTheme.shades.neutral,
          },
          '&.Mui-expanded': {
            marginBottom: 0,
            marginTop: 0,
            '&::before': {
              opacity: 1,
            },
          },
          '&:first-of-type': {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiFormControlLabel-label': {
            fontSize: 12,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          '.MuiAccordionSummary-content': {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: colorsTheme.background.color1,
          borderRadius: 8,
          overflow: 'hidden',
          boxShadow: '0px 1px 3px 0px rgba(25, 33, 61, 0.10)',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: colorsTheme.text.neutral,
          fontSize: 12,
          border: 'none',
          textTransform: 'none',
          paddingTop: 6,
          paddingBottom: 6,
          paddingRight: 16,
          paddingLeft: 16,
          '&.Mui-selected': {
            boxShadow:
              '0px 2px 5px 0px rgba(20, 88, 201, 0.17), 0px -2px 0.3px 0px rgba(14, 56, 125, 0.18) inset, 0px 2px 1px 0px rgba(255, 255, 255, 0.22) inset',
            color: colorsTheme.text.light,
            backgroundColor: colorsTheme.primary.main,
            '&:hover': {
              backgroundColor: colorsTheme.background.linearGradient,
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${colorsTheme.border.neutral}`,
          },
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 1,
            paddingBottom: 1,
            borderRadius: 4,
            fontSize: 12,
          },
        },
        {
          props: { variant: 'text', size: 'small' },
          style: {
            fontSize: 12,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: 8,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 12,
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: `1px solid ${colorsTheme.drawer.primary}`,
          boxShadow: '0px 2px 4px 0px rgb(25, 33, 61, 0.08)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: shadowTheme.popper,
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default AppTheme;
