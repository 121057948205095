import { axiosApi } from 'api/axios';
import { routes } from 'api/routes';
import { AxiosResponse } from 'axios';
import { ComparisonInterface } from 'interfaces';
import { SingleComparison } from 'interfaces/ComparisonInterface';

export interface ComparisonQueryParams {
  count?: string;
  searchQuery?: string;
  ordering?: 'title' | '-popularity';
  id?: string;
  page?: number | string;
  perPage?: number;
}

export interface CreateComparisonBody {
  scenarioId: string;
  models: string[];
  inputTokens: number | string;
  outputTokens: number | string;
  requestsPerDay: number | string;
}

export type Comparison<T> = T extends { id: string } ? SingleComparison[] : ComparisonInterface;

const getComparisonData = async <T extends ComparisonQueryParams>(props: T) => {
  const response: AxiosResponse<Comparison<T>> = await axiosApi.get(
    routes.COMPARISON({ ...props })
  );
  return response.data;
};

const createComparison = async (createComparisonBody: CreateComparisonBody) => {
  const response = await axiosApi.post(routes.CREATE_COMPARISON(), createComparisonBody);

  return response.data;
};

export { createComparison, getComparisonData };
