import { Box, Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import theme from 'themes/theme';
import { ModelCard } from 'pages/HomePage/components/ModelCard';
import useModels from 'hooks/useModels';
import ErrorState from 'components/ErrorState';
import LoadingState from 'components/LoadingState';
import { formatSharesNumber } from 'utils/formatSharesNumber';
import NoResultFoundState from 'components/NoResultFoundState';
import { RoutesVars } from 'const/constRoutes';
import stringsJSON from 'assets/strings/strings.json';
const DEFAULT_MODELS_COUNTS = 4;

interface Props {
  searchText?: string;
  handleShareModel: (id: string) => void;
}

const { popularModelsSectionTitle, popularModelsSearchTitle, allModelsLinkText } =
  stringsJSON.homePage;

const PopularModelsSectionHomepage = ({ searchText = '', handleShareModel }: Props) => {
  const { modelsData, isError, isLoading, refetch } = useModels({
    count: searchText ? '' : DEFAULT_MODELS_COUNTS.toString(),
    searchQuery: searchText,
    ordering: '-popularity',
  });

  const headingText = searchText ? popularModelsSearchTitle : popularModelsSectionTitle;
  const noResultsFound = !modelsData || !modelsData?.length;

  const renderGrid = () => {
    if (isLoading) {
      return <LoadingState testId="homepage-models-spinner" />;
    }

    if (isError) {
      return <ErrorState refetch={refetch} />;
    }

    if (noResultsFound) {
      return <NoResultFoundState />;
    }

    return modelsData.map(({ title, description, id, shares, iconSrc }) => {
      const formattedShares = formatSharesNumber(shares);
      return (
        <Grid
          item
          md={6}
          key={id}
          sx={{
            minWidth: 300,
            scrollSnapAlign: 'start',

            [theme.breakpoints.up('md')]: {
              width: '100%',
            },
          }}
        >
          <ModelCard
            title={title}
            description={description}
            shares={formattedShares}
            iconSrc={iconSrc}
            modelId={id}
            handleShareModel={handleShareModel}
          />
        </Grid>
      );
    });
  };

  return (
    <Box
      component="section"
      sx={{
        my: 3,
        [theme.breakpoints.up('md')]: {
          my: 5,
        },
      }}
    >
      <SectionHeading
        buttonText={allModelsLinkText}
        headingText={headingText}
        link={RoutesVars.ALL_MODElS}
      />

      <Grid
        container
        spacing={2}
        marginTop={2}
        sx={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          WebkitOverflowScrolling: 'touch',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
          [theme.breakpoints.up('md')]: {
            flexWrap: 'wrap',
          },
        }}
      >
        {renderGrid()}
      </Grid>
    </Box>
  );
};

export default PopularModelsSectionHomepage;
