import { Box, Button } from '@mui/material';
import CategoriesFilterInput from 'pages/CompareMultiMetricsPage/components/FilterFields/CategoriesFilterInput';
import ModelPickerFilter from 'pages/CompareMultiMetricsPage/components/FilterFields/ModelPickerFilter';
import SearchInput from 'pages/CompareMultiMetricsPage/components/FilterFields/SearchInput';
import ThemesFilter from 'pages/CompareMultiMetricsPage/components/FilterFields/ThemesFilters';
import { MultimetricSelectedFilters } from 'utils/MultimetricForm/types';

interface Props {
  isLoading: boolean;
  isRefetching: boolean;
  onSubmit: (data: MultimetricSelectedFilters) => void;
}

const ComparisonFilterSection = ({ isLoading, isRefetching, onSubmit }: Props) => {
  return (
    <Box mt={{ xs: 4, md: 5.25 }}>
      <Box
        display="flex"
        maxWidth="100%"
        alignContent="center"
        justifyContent="space-between"
        gap={1.5}
        mb={1.25}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Box minWidth={{ xs: '50%', md: '62.5%' }} flexGrow={1}>
          <SearchInput />
        </Box>
        <Box flexGrow={1}>
          <ModelPickerFilter onSubmit={onSubmit} />
        </Box>
      </Box>

      <Box
        display="flex"
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        alignContent="center"
        justifyContent="space-between"
        gap={1.5}
      >
        <Box minWidth={{ xs: 125, sm: '53.5%' }}>
          <ThemesFilter />
        </Box>
        <Box minWidth={125} flexGrow={1}>
          <CategoriesFilterInput />
        </Box>
        <Box minWidth={116} mt={0.75}>
          <Button variant="contained" fullWidth type="submit" disabled={isLoading || isRefetching}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ComparisonFilterSection;
