import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { generateDefaultModelsValues } from 'utils/MultimetricForm/formUtils';
import { CostOptimizationScenariosInterface, ModelsInterface } from 'interfaces';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CostOptimizationFormValidation } from 'const/constants';
import stringsJSON from 'assets/strings/strings.json';
type FormProps = {
  children: React.ReactNode;
  onSubmit: SubmitHandler<PlaygroundFormSchema>;
  modelsData: ModelsInterface[];
  costOptimizationScenariosData: CostOptimizationScenariosInterface[];
};

const strings = stringsJSON.costOptimizationPage;

const modelSchema = z.record(z.object({ checked: z.boolean() }));

const numberSchema = z
  .string()
  .refine(
    (val) => {
      return val !== '';
    },
    {
      message: strings.allFieldsRequiredErrorMessage,
    }
  )
  .refine(
    (val) => {
      const num = Number(val);
      return (
        !isNaN(num) &&
        Number.isInteger(num) &&
        num >= CostOptimizationFormValidation.MINIMUM_INPUT_NUMBER
      );
    },
    {
      message: strings.minNumberErrorMessage,
    }
  )
  .refine(
    (val) => {
      const num = Number(val);
      return (
        !isNaN(num) &&
        Number.isInteger(num) &&
        num <= CostOptimizationFormValidation.MAXIMUM_INPUT_NUMBER
      );
    },
    {
      message: strings.maxNumberErrorMessage,
    }
  );

const formSchema = z.object({
  modelPicker: modelSchema
    .refine(
      (data) =>
        Object.values(data).filter((item) => item.checked).length >=
        CostOptimizationFormValidation.MINIMUM_NUMBER_OF_SELECTED_MODELS,
      {
        message: strings.allFieldsRequiredErrorMessage,
      }
    )
    .refine(
      (data) =>
        Object.values(data).filter((item) => item.checked).length <=
        CostOptimizationFormValidation.MAXIMUM_NUMBER_OF_SELECTED_MODELS,
      {
        message: strings.maxModelsErrorMessage,
      }
    ),
  scenarioPicker: z.string().min(1, strings.selectScenarioErrorMessage),
  totalNumberOfRequestsPerDay: numberSchema,
  numberOfInputTokensPerRequest: numberSchema,
  numberOfOutputTokensPerRequest: numberSchema,
});

export type PlaygroundFormSchema = z.infer<typeof formSchema>;

const CostOptimizationFormWrapper = ({ children, onSubmit, modelsData }: FormProps) => {
  const methods = useForm<PlaygroundFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      modelPicker: generateDefaultModelsValues(modelsData),
      scenarioPicker: '',
      totalNumberOfRequestsPerDay: '',
      numberOfInputTokensPerRequest: '',
      numberOfOutputTokensPerRequest: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default CostOptimizationFormWrapper;
