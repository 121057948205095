import { ArrowForward } from '@mui/icons-material';
import { Props } from './types';
import { Box, Button, Divider, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const SectionHeading = ({
  level = 'h2',
  variant = 'h3',
  buttonText,
  headingText,
  link,
}: Props) => {
  return (
    <>
      <Box marginBottom="1.2rem" display="flex" justifyContent="space-between">
        <Typography variant={variant} component={level} sx={{ lineHeight: 1.85 }}>
          {headingText}
        </Typography>
        {buttonText && link && (
          <NavLink to={link}>
            <Button size="small" variant="text" endIcon={<ArrowForward sx={{ width: 14 }} />}>
              {buttonText}
            </Button>
          </NavLink>
        )}
      </Box>
      <Divider orientation="horizontal" variant="fullWidth" />
    </>
  );
};
