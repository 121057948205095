import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'api/queryKeys';
import {
  GetComparisonExportFileServiceParams,
  getComparisonExportFileService,
} from 'api/services/GetComparisonExportFileService';

const useGetComparisonExportFile = ({ comparisonId }: GetComparisonExportFileServiceParams) => {
  const {
    data: comparisonExportFileData,
    isLoading,
    isError,
    error: comparisonExportError,
    isFetched,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.GET_COMPARISON_EXPORT_FILE, comparisonId],
    queryFn: () =>
      getComparisonExportFileService({
        comparisonId,
      }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    comparisonExportFileData,
    isError,
    isLoading,
    comparisonExportError,
    isFetched,
    isFetching,
    refetch,
  };
};

export default useGetComparisonExportFile;
