import { Box, Typography } from '@mui/material';
import WelcomeSection from './sections/WelcomeSection';
import PlaygroundFormWrapper, {
  PlaygroundFormSchemaType,
} from './components/PlaygroundFormWrapper';
import { useState } from 'react';
import useMutateLlmQuery from 'hooks/useMutateLlmQuery';
import { SingleLlmMessage } from 'api/services/LlmQueryService';
import stringsJSON from 'assets/strings/strings.json';
export interface GlobalPromptResponse {
  modelId: string;
  history: SingleLlmMessage[];
  conversationId: string;
  isError?: boolean;
}

export interface GlobalPromptError {
  id: string;
  error: boolean;
}

const strings = stringsJSON.playgroundPage;

const PlaygroundPage = () => {
  const [responseGlobal, setResponseGlobal] = useState<(GlobalPromptResponse | undefined)[]>([]);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [globalError, setGlobalError] = useState<GlobalPromptError[]>();
  const { mutateAsync } = useMutateLlmQuery();

  const onSubmit = async (data: PlaygroundFormSchemaType) => {
    setGlobalLoading(true);
    setGlobalError([]);

    if (!data.cards.length) {
      return;
    }

    try {
      const responses = await Promise.all(
        data.cards
          .filter((card) => card.isSynced)
          .map(async (card) => {
            try {
              const response = await mutateAsync({
                conversationId: card.conversationId || null,
                modelId: card.modelId,
                prompt: data.globalPrompt,
              });

              return {
                modelId: card.modelId,
                history: response.conversation,
                conversationId: response.conversationId,
              };
            } catch (error) {
              setGlobalError((prev) => [...(prev || []), { id: card.modelId, error: true }]);
              return {
                modelId: card.modelId,
                history: [],
                conversationId: '',
              };
            }
          })
      );

      setResponseGlobal(responses);
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <Box>
      <WelcomeSection />
      <Box my={5.5}>
        <Box mb={3}>
          <Typography variant="h3" component="h2" mb={0.5}>
            {strings.promptSectionTitle}
          </Typography>
          <Typography>{strings.promptSectionDescription}</Typography>
        </Box>
        <PlaygroundFormWrapper
          onSubmit={onSubmit}
          globalError={globalError}
          setGlobalError={setGlobalError}
          globalLoading={globalLoading}
          responseGlobal={responseGlobal}
        />
      </Box>
    </Box>
  );
};

export default PlaygroundPage;
