import { Box, Button, InputBase, Toolbar } from '@mui/material';
import { styled } from '@mui/material';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';

export const ModalContent = styled(Box)({
  width: theme.spacing(48.5),
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: colorsTheme.background.color1,
  boxShadow: theme.spacing(3),
  outline: 'none',
  borderRadius: theme.spacing(1.25),
  [theme.breakpoints.up('md')]: {
    top: '30%',
    width: theme.spacing(85.5),
  },
});

export const ModalToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: `${theme.spacing(6)} !important`,
  paddingLeft: `${theme.spacing(1.75)} !important`,
  paddingRight: `${theme.spacing(1)} !important`,
  [theme.breakpoints.up('md')]: {
    paddingLeft: `${theme.spacing(4)} !important`,
    paddingRight: `${theme.spacing(3)} !important`,
  },
});

export const CopyLinkWrapper = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(2),
  '&:hover': {
    color: colorsTheme.primary.main,
  },
});

export const ContentBox = styled(Box)({
  padding: `${theme.spacing(2.5)} ${theme.spacing(1.5)}`,
  paddingBottom: `${theme.spacing(4)}`,
  boxSizing: 'border-box',
  gap: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
});

export const InputWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1.5),
});

export const StyledInputBase = styled(InputBase)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  gap: theme.spacing(0.75),
  width: '90%',
  padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
  border: `${theme.spacing(0.125)} solid ${colorsTheme.drawer.primary}`,
  boxShadow: shadowTheme.input,
});

export const ShareButton = styled(Button)(({ theme }) => ({
  color: colorsTheme.background.color1,
  fontWeight: 500,
  width: theme.spacing(10),
  height: theme.spacing(5.5),
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(1),
  background: 'linear-gradient(180deg, #2B7AFB 0%, #2174FD 100%, #213BFD 100%)',
  boxShadow:
    '0px 2px 5px 0px rgba(20, 88, 201, 0.17), 0px -2px 0.3px 0px rgba(14, 56, 125, 0.18) inset, 0px 2px 1px 0px rgba(255, 255, 255, 0.22) inset',
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(9),
  },
  '&:disabled': {
    background: colorsTheme.background.disabled,
    color: colorsTheme.text.light,
  },
}));

export const FooterWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing(0.75),
  color: colorsTheme.text.info,
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
  },
});

export const CopyLinkButton = styled(Box)({
  fontWeight: 600,
  fontSize: 12,
  paddingBottom: theme.spacing(0.2),
  color: 'inherit',
  letterSpacing: 0.3,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});
