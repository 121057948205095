import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { CategoryFormState } from 'utils/MultimetricForm/types';
import { Categories } from 'interfaces/MultiMetricComparisonInterface';
import CriteriaCheckbox from '../CriteriaCheckbox';
import { colorsTheme } from 'themes/theme';

interface CategoryCheckboxProps {
  category: Categories;
}

const CategoryCheckbox: React.FC<CategoryCheckboxProps> = ({ category }) => {
  const { control, watch, setValue } = useFormContext();
  const categoryId = category.id;

  const categoryState = watch(`categoriesPicker.${categoryId}`) as CategoryFormState;
  const criteriaStates = categoryState.criteria;

  const allChecked = Object.values(criteriaStates).every(Boolean);
  const anyChecked = Object.values(criteriaStates).some(Boolean);
  const isIndeterminate = !allChecked && anyChecked;

  const handleParentCheckboxChange = () => {
    const isChecked = !categoryState.checked;
    setValue(`categoriesPicker.${categoryId}`, {
      checked: isChecked,
      criteria: Object.fromEntries(
        Object.keys(criteriaStates).map((criteriaName) => [criteriaName, isChecked])
      ),
    });
  };

  return (
    <FormGroup sx={{ display: 'flex', flexDirection: 'column', p: 0, m: 0 }}>
      <FormControlLabel
        sx={{
          m: 0,
          pr: 1.5,

          '&:hover, &:focus': {
            backgroundColor: `${colorsTheme.background.optionHover}`,
          },
        }}
        control={
          <Controller
            name={`categoriesPicker.${categoryId}.checked`}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                sx={{ p: 1.5, pl: 3.5 }}
                size="small"
                checked={categoryState.checked}
                indeterminate={isIndeterminate}
                onChange={handleParentCheckboxChange}
              />
            )}
          />
        }
        label={category.name}
      />
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', ml: 3, p: 0 }}>
        {category.criteria.map((criteria) => (
          <CriteriaCheckbox
            key={criteria.id}
            categoryId={categoryId}
            criteriaId={criteria.id}
            criteriaName={criteria.name}
          />
        ))}
      </FormGroup>
    </FormGroup>
  );
};

export default CategoryCheckbox;
