import React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

interface CustomSelectInputProps extends InputBaseProps {
  notched?: boolean;
}

const CustomSelectInput = React.forwardRef<HTMLInputElement, CustomSelectInputProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ notched, ...other }, ref) => {
    // notched is intentionally not used
    return <InputBase ref={ref} {...other} />;
  }
);

CustomSelectInput.displayName = 'CustomSelectInput';

export default CustomSelectInput;
