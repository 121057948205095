import { Box } from '@mui/material';

const BackgroundContainerGradients = () => {
  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
      <Box
        component="svg"
        xmlns="http://www.w3.org/2000/svg"
        width="120%"
        height="100%"
        viewBox="0 0 1014 497"
        fill="none"
        sx={{
          position: 'absolute',
          opacity: '0.2',
        }}
      >
        <g filter="url(#filter0_f_219_4794)">
          <path
            // eslint-disable-next-line max-len
            d="M968.348 810.133C876.417 890.691 758.108 939.162 629.021 939.162C336.311 939.162 99.0232 689.939 99.0232 382.507C99.0232 284.29 123.242 192.013 165.75 111.906C49.225 214.017 -24.9209 367.68 -24.9209 539.532C-24.9209 846.963 212.367 1096.19 505.077 1096.19C704.272 1096.19 877.801 980.769 968.348 810.133Z"
            fill="#AC0CB9"
            fillOpacity="0.7"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_219_4794"
            x="-158.032"
            y="-21.2053"
            width="1259.49"
            height="1250.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="66.5558" result="effect1_foregroundBlur_219_4794" />
          </filter>
        </defs>
      </Box>
      <Box
        sx={{
          width: '110%',
          height: '100%',
          position: 'absolute',
          opacity: 0.8,
          background: `radial-gradient(58% 48% at 45% 55%, rgba(6,121,255,0.28) 0%, transparent)`,
        }}
      />
    </Box>
  );
};

export default BackgroundContainerGradients;
