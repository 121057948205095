import { Box, Divider, Typography } from '@mui/material';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.playgroundPage;

const WelcomeSection = () => {
  return (
    <Box component="section">
      <Box pt={6.5} pb={5}>
        <Typography component="h1" variant="h1" mb={0.75}>
          {strings.pageTitle}
        </Typography>
        <Typography>{strings.pageDescription}</Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export default WelcomeSection;
