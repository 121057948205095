import { useState, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import useModels from 'hooks/useModels';
import useCostOptimizationScenarios from 'hooks/useCostOptimizationScenarios';
import CostOptimizationScenariosInterface from 'interfaces/CostOptimizationScenariosInterface';
import { ModelsInterface } from 'interfaces';
import { PlaygroundFormSchema } from '../../CostOptimizationFormWrapper';

type Option = CostOptimizationScenariosInterface | ModelsInterface;

export const useCostOptimizationSearchInput = () => {
  const { modelsData: models } = useModels({ ordering: 'title' });
  const { costOptimizationScenariosData } = useCostOptimizationScenarios();

  const { setValue } = useFormContext<PlaygroundFormSchema>();
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const allOptions = useMemo<Option[]>(() => {
    if (!costOptimizationScenariosData || !models) return [];
    return [...models, ...costOptimizationScenariosData];
  }, [models, costOptimizationScenariosData]);

  const handleOptionSelect = useCallback(
    (option: Option | null) => {
      if (option) {
        if ('title' in option) {
          // It's a model
          setValue(`modelPicker.${option.id}`, { checked: true });
        } else {
          // It's a scenario
          setValue('scenarioPicker', option.id);
        }
        setSelectedOption(option);
      } else {
        setSelectedOption(null);
      }
      setInputValue('');
    },
    [setValue]
  );

  const handleInputChange = useCallback((newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  const getOptionLabel = useCallback((option: Option) => {
    if ('title' in option) return option.title;
    return option.name;
  }, []);

  return {
    allOptions,
    inputValue,
    selectedOption,
    handleOptionSelect,
    handleInputChange,
    getOptionLabel,
  };
};
