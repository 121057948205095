import { Box } from '@mui/material';

import theme from 'themes/theme';

interface Props {
  /** Position of gradient from left side, defaults to center which is equal to '50%' */
  positionHorizontal?: string;
  /** Color of gradient */
  mainColor: string;
}

export const BoxWithGradient = ({ positionHorizontal = '50%', mainColor }: Props) => {
  return (
    <Box
      sx={{
        display: 'none',
        width: '100%',
        height: '200%',
        position: 'absolute',
        bottom: '0',
        left: positionHorizontal,
        transform: 'translate(-50%, 60%)',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `radial-gradient(circle, ${mainColor} 0%, transparent 38%, transparent 80%)`,
        [theme.breakpoints.up('md')]: { display: 'block' },
      }}
    />
  );
};
