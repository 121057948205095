import {
  Checkbox,
  Select,
  ListItemText,
  FormControl,
  InputLabel,
  InputBase,
  Typography,
  MenuItem,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { colorsTheme, shadowTheme } from 'themes/theme';
import { Controller, useFormContext } from 'react-hook-form';
import { useThemesFilter } from './useThemeFilter';
import stringsJSON from 'assets/strings/strings.json';
const strings = stringsJSON.multimetricComparisonPage;

const ThemesFilter = () => {
  const { control } = useFormContext();
  const { themes, themesPicker, categoriesPicker, totalCheckedThemes, handleThemeChange } =
    useThemesFilter();

  const ThemesLabelText =
    totalCheckedThemes > 0
      ? `${strings.themesFilterPlaceholder} (${totalCheckedThemes})`
      : strings.themesFilterPlaceholder;

  const MenuProps = {
    PaperProps: {
      style: {
        boxShadow: shadowTheme.popper,
        marginTop: 5,
      },
    },
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel
          id="theme-picker-label"
          sx={{
            transform: `translate(15px, 13px) scale(1)`,
            fontSize: 12,
            '&.Mui-focused': {
              color: 'inherit',
            },
            '&.MuiFormLabel-filled': {
              clip: 'rect(0 0 0 0)',
              clipPath: 'inset(50%)',
              height: '1px',
              overflow: 'hidden',
              position: 'absolute',
              whiteSpace: 'nowrap',
              width: '1px',
            },
          }}
        >
          {ThemesLabelText}
        </InputLabel>
        <Controller
          name="themesPicker"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              labelId="theme-picker-label"
              value={Object.keys(themesPicker).filter((key) => themesPicker[key]?.checked)}
              sx={{
                minHeight: 42,
                borderRadius: 2,
                paddingX: 1,
                boxShadow: shadowTheme.input,
                backgroundColor: colorsTheme.background.color1,
                py: 0.375,
                border: `1px solid ${colorsTheme.drawer.primary}`,
                cursor: 'pointer',
              }}
              MenuProps={MenuProps}
              input={<InputBase />}
              IconComponent={(props) => (
                <KeyboardArrowDown
                  {...props}
                  fontSize="small"
                  htmlColor={colorsTheme.border.color4}
                />
              )}
              onChange={handleThemeChange}
              renderValue={() => (
                <Typography sx={{ ml: 0.75, mt: 0.5, fontSize: '12px' }}>
                  {ThemesLabelText}
                </Typography>
              )}
            >
              {themes.map((theme) => {
                const themeState = themesPicker[theme.id] || {};
                const isIndeterminate =
                  theme.categories.some(
                    (cat) =>
                      categoriesPicker[cat.id]?.checked || categoriesPicker[cat.id]?.indeterminate
                  ) && !themeState.checked;

                return (
                  <MenuItem
                    key={theme.id}
                    value={theme.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&.Mui-selected': {
                        background: 'transparent',
                      },
                      '&:hover, &:focus, &.Mui-selected:focus, &.Mui-selected:hover': {
                        backgroundColor: colorsTheme.background.optionHover,
                      },
                    }}
                  >
                    <Checkbox
                      size="small"
                      sx={{ p: 0.75 }}
                      checked={themeState.checked || false}
                      indeterminate={isIndeterminate}
                      data-testid={`theme-picker-checkbox-${theme.id}`}
                    />
                    <ListItemText primary={theme.name} sx={{ '& span': { fontSize: 12 } }} />
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};

export default ThemesFilter;
