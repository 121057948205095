import { useMemo, useState } from 'react';
import { Box, Typography, Autocomplete, Checkbox, PopperProps } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox, KeyboardArrowDown, Memory } from '@mui/icons-material';

import {
  CustomPopper,
  StyledLabelWrapper,
  StyledModelOption,
  StyledModelPickerInput,
  StyledPaperComponent,
} from 'components/FormFields/ModelPickerAutocomplete/styled';
import {
  PlaygroundCardSchema,
  PlaygroundFieldArray,
  PlaygroundFormSchemaType,
} from '../../PlaygroundFormWrapper';
import { IntegratedModelsInterface } from 'interfaces';
import { useDebounce } from 'utils/useDebounce';
import { useFormContext } from 'react-hook-form';
import stringsJSON from 'assets/strings/strings.json';

const { playgroundPage, general } = stringsJSON;

interface Props {
  models: IntegratedModelsInterface[];
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  playgroundFieldsArray: PlaygroundFieldArray;
}

const PlaygroundModelsField = ({
  models,
  playgroundFieldsArray: { fields, append, replace, remove, update },
  setShowErrors,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const { setError, clearErrors } = useFormContext<PlaygroundFormSchemaType>();

  const existingModels = fields.filter(({ modelId }) =>
    models.some((model) => model.id === modelId)
  );

  const emptyCards = fields.filter(({ isEmpty }) => isEmpty);

  const getLabel = () => {
    return (
      <StyledLabelWrapper>
        <Memory sx={{ fontSize: 16 }} color="inherit" />
        <Typography fontSize={12}>
          {general.modelPickerPlaceholder}{' '}
          {existingModels.length > 0 && `(${existingModels.length})`}
        </Typography>
      </StyledLabelWrapper>
    );
  };

  const PopperComponent = useMemo(() => {
    return function MemoizedPopper(props: PopperProps) {
      return (
        <CustomPopper
          {...props}
          popperOptions={{
            modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
          }}
        />
      );
    };
  }, []);

  const displayErrorMessage = useDebounce({
    func: () => {
      setShowErrors(true);
      setError('cards', {
        message: playgroundPage.maxModelsErrorMessage,
      });
      setTimeout(() => {
        clearErrors('cards');
        setShowErrors(false);
      }, 4000);
    },
    timer: 200,
  });

  const handleChange = (newValue: IntegratedModelsInterface[]) => {
    if (newValue.length > 4 && !emptyCards.length) {
      displayErrorMessage();
    } else {
      if (newValue.length < fields.length && existingModels.length === fields.length) {
        const newFields = fields.filter((model) => newValue.some(({ id }) => id === model.modelId));
        replace(newFields);
      } else {
        if (newValue.length < existingModels.length) {
          const foundField = existingModels.find(
            (existingItem) => !newValue.some((newItem) => newItem.id === existingItem.modelId)
          );
          if (foundField) {
            const foundFieldIndex = fields.indexOf(foundField);
            remove(foundFieldIndex);
          }
          return;
        }

        if (fields.length >= 4 && !emptyCards.length) {
          displayErrorMessage();
          return;
        }

        const newModelId = newValue.filter(
          (model) => !fields.some(({ modelId }) => modelId === model.id)
        )[0].id;

        const newCard: PlaygroundCardSchema = {
          modelId: newModelId,
          conversation: [],
          isSynced: true,
          isEmpty: false,
          conversationId: '',
        };

        if (emptyCards.length > 0) {
          const fieldIndex = fields.indexOf(emptyCards[0]);
          update(fieldIndex, newCard);
          return;
        }

        append(newCard);
      }
    }
  };

  if (!models) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" width={{ xs: '100%', md: 'auto' }} gap={1} flex={1}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="models-picker"
        options={models}
        noOptionsText="No models found."
        value={models.filter((option) => fields.some((field) => field.modelId === option.id))}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') {
            setInputValue(newInputValue);
          }
        }}
        onBlur={() => setInputValue('')}
        onChange={(_, newValue) => handleChange(newValue)}
        inputValue={inputValue}
        getOptionLabel={(option) => option.id}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) => {
            const searchValue = inputValue.toLowerCase();
            return option.title.toLowerCase().includes(searchValue);
          })
        }
        renderOption={(props, option) => {
          const { key, ...modelProps } = props;
          return (
            <StyledModelOption key={key} {...modelProps}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                checked={fields.some((field) => field.modelId === option.id)}
              />
              <Box display="flex" gap={1.5}>
                <img
                  src={
                    option.iconSrc.startsWith('http') ? option.iconSrc : `http://${option.iconSrc}`
                  }
                  alt={option.title}
                  height="18"
                  width="18"
                />
                <Typography lineHeight={1.5} fontSize={12}>
                  {option.title}
                </Typography>
              </Box>
            </StyledModelOption>
          );
        }}
        PopperComponent={PopperComponent}
        PaperComponent={StyledPaperComponent}
        style={{ maxWidth: 556, width: '100%', minWidth: 129 }}
        renderInput={(params) => (
          <StyledModelPickerInput
            {...params}
            fullWidth
            label={getLabel()}
            placeholder={general.modelPickerSearchPlaceholder}
          />
        )}
        renderTags={() => null}
        popupIcon={<KeyboardArrowDown fontSize="small" />}
        clearIcon={null}
        disablePortal
      />
    </Box>
  );
};

export default PlaygroundModelsField;
