import React, { useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { colorsTheme } from 'themes/theme';

interface CriteriaCheckboxProps {
  categoryId: string;
  criteriaId: string;
  criteriaName: string;
}

const CriteriaCheckbox: React.FC<CriteriaCheckboxProps> = ({
  categoryId,
  criteriaId,
  criteriaName,
}) => {
  const { control, setValue, getValues } = useFormContext();

  const updateCategoryState = useCallback(() => {
    const categoryState = getValues(`categoriesPicker.${categoryId}`);
    const criteriaStates = Object.values(categoryState.criteria);
    const allChecked = criteriaStates.every(Boolean);
    const someChecked = criteriaStates.some(Boolean);

    setValue(`categoriesPicker.${categoryId}.checked`, allChecked, { shouldDirty: true });
    setValue(`categoriesPicker.${categoryId}.indeterminate`, !allChecked && someChecked, {
      shouldDirty: true,
    });
  }, [categoryId, getValues, setValue]);

  const handleCriteriaChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(`categoriesPicker.${categoryId}.criteria.${criteriaId}`, e.target.checked, {
        shouldDirty: true,
      });
      updateCategoryState();
    },
    [categoryId, criteriaId, setValue, updateCategoryState]
  );

  return (
    <FormControlLabel
      label={criteriaName}
      sx={{
        m: 0,
        pr: 1.5,
        '&:hover, &:focus': {
          backgroundColor: `${colorsTheme.background.optionHover}`,
        },
      }}
      control={
        <Controller
          name={`categoriesPicker.${categoryId}.criteria.${criteriaId}`}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              size="small"
              sx={{
                p: 1.5,
                pl: 3.5,
              }}
              checked={field.value}
              onChange={handleCriteriaChange}
            />
          )}
        />
      }
    />
  );
};

export default CriteriaCheckbox;
