const ACCESS_TOKEN = 'AMF_access_token';
const EXPIRES_AT = 'AMF_access_token_expires_at';

const getToken = (): string | null => localStorage.getItem(ACCESS_TOKEN);
const isAuthenticated = (): boolean => !!getToken();

const setToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

const deleteToken = (): void => {
  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(ACCESS_TOKEN);
};

export { isAuthenticated, setToken, getToken, deleteToken, ACCESS_TOKEN, EXPIRES_AT };
