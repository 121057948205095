import { Box } from '@mui/material';
import ComparePageWelcomeSection from 'components/ComparePageWelcomeSection';
import CostOptimizationFilterSection from './sections/CostOptimizationFilterSection';
import CostOptimizationFormWrapper, {
  PlaygroundFormSchema,
} from './components/CostOptimizationFormWrapper';
import useModels from 'hooks/useModels';
import useCostOptimizationScenarios from 'hooks/useCostOptimizationScenarios';
import PopularComparisonsSection from 'components/PopularComparisonSection';
import LoadingState from 'components/LoadingState';
import ShareModel from 'components/ShareModal';
import { useState } from 'react';
import useCreateComparison from 'hooks/useCreateComparison';
import { CreateComparisonBody } from 'api/services/ComparisonService';
import SingleComparisonSection from './components/SingleComparisonSection';
import toast from 'react-hot-toast';
import SelectedFiltersSection from './sections/SelectedFiltersSection';
import { SingleComparison } from 'interfaces/ComparisonInterface';
import stringsJSON from 'assets/strings/strings.json';

const mapCostOptimizationFormStateToPostBody = (
  data: PlaygroundFormSchema
): CreateComparisonBody => {
  const checkedModels = Object.keys(data.modelPicker).filter(
    (key) => data.modelPicker[key].checked
  );

  return {
    scenarioId: data.scenarioPicker,
    models: checkedModels,
    inputTokens: data.numberOfInputTokensPerRequest,
    outputTokens: data.numberOfOutputTokensPerRequest,
    requestsPerDay: data.totalNumberOfRequestsPerDay,
  };
};

const strings = stringsJSON.costOptimizationPage;

const CompareCostOptimizationPage = () => {
  const [selectedScenarioData, setSelectedScenarioData] = useState<SingleComparison | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comparisonId, setComparisonId] = useState('');
  const { modelsData, isLoading: modelsLoading } = useModels({
    ordering: 'title',
  });
  const { costOptimizationScenariosData, isLoading: scenariosLoading } =
    useCostOptimizationScenarios();

  const handleShareComparison = (id: string) => {
    setIsModalOpen(true);
    setComparisonId(id);
  };

  const { mutate } = useCreateComparison();

  const onSubmit = (data: PlaygroundFormSchema) => {
    const body = mapCostOptimizationFormStateToPostBody(data);
    mutate(body, {
      onSuccess: (responseData: SingleComparison) => {
        setSelectedScenarioData(responseData);
      },
      onError: () => {
        toast.error('Something went wrong');
      },
    });
  };

  const filtersDataIsEmpty = !modelsData || !costOptimizationScenariosData;
  const filtersDataIsLoading = modelsLoading || scenariosLoading;

  if (filtersDataIsEmpty) {
    return (
      <Box>
        <ComparePageWelcomeSection
          title={strings.pageTitle}
          description={strings.pageDescription}
        />
        <Box height={142} display="flex" alignItems="center" justifyContent="center" width="100%">
          {filtersDataIsLoading && <LoadingState />}
        </Box>
        <Box my={6.5}>
          <PopularComparisonsSection handleShareComparison={handleShareComparison} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <ShareModel
        isOpen={isModalOpen}
        id={comparisonId}
        setIsOpen={setIsModalOpen}
        isComparison={true}
      />
      <CostOptimizationFormWrapper
        onSubmit={onSubmit}
        modelsData={modelsData}
        costOptimizationScenariosData={costOptimizationScenariosData}
      >
        <ComparePageWelcomeSection
          title={strings.pageTitle}
          description={strings.pageDescription}
        />
        <CostOptimizationFilterSection setSelectedScenarioData={setSelectedScenarioData} />
        <Box>
          <SelectedFiltersSection onSubmit={onSubmit} />
        </Box>
        <Box sx={{ my: 6.5 }}>
          {selectedScenarioData ? (
            <SingleComparisonSection
              selectedScenarioData={selectedScenarioData}
              handleShareComparison={handleShareComparison}
            />
          ) : (
            <PopularComparisonsSection handleShareComparison={handleShareComparison} />
          )}
        </Box>
      </CostOptimizationFormWrapper>
    </Box>
  );
};

export default CompareCostOptimizationPage;
