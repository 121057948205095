import styled from '@emotion/styled';
import { Box, Paper, Popper, TextField } from '@mui/material';
import theme, { colorsTheme, shadowTheme } from 'themes/theme';

export const CustomPopper = styled(Popper)({
  boxShadow: shadowTheme.popper,
  borderRadius: theme.spacing(1),
  border: 'none',
  overflow: 'hidden',
  minWidth: 170,
  '& .MuiAutocomplete-paper': {
    boxShadow: 'none',
    margin: 0,
  },
});

export const StyledModelOption = styled('li')({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),

  '&:hover, &.MuiAutocomplete-option.Mui-focused': {
    backgroundColor: `${colorsTheme.background.optionHover} !important`,
  },
});

export const StyledModelPickerInput = styled(TextField)({
  boxShadow: shadowTheme.input,
  paddingTop: theme.spacing(0.375),
  paddingBottom: theme.spacing(0.375),
  backgroundColor: colorsTheme.background.color1,
  border: `${theme.spacing(0.125)} solid ${colorsTheme.drawer.primary}`,
  borderRadius: theme.spacing(1),
  '& .MuiOutlinedInput-root': {
    fontSize: 12,
    padding: `0 0 0 ${theme.spacing(1)}`,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      color: colorsTheme.text.neutral,
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    transform: `translate(${theme.spacing(1.25)}, ${theme.spacing(1.25)}) scale(1) !important`,
  },
  '& .MuiInputLabel-root[data-shrink="true"]': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
});

export const StyledPaperComponent = styled(Paper)({
  '& .MuiAutocomplete-listbox': {
    padding: '0 !important',
    '& .MuiAutocomplete-option': {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      backgroundColor: 'transparent !important',
      '&': {
        backgroundColor: 'inherit',
      },
    },
  },
});

export const StyledLabelWrapper = styled(Box)({
  display: 'flex',
  color: colorsTheme.text.neutral,
  alignItems: 'center',
  gap: theme.spacing(0.5),
});
