import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { MultimetricSelectedFilters } from './types';
import { useContext, useEffect } from 'react';
import { AppContext } from 'context/AppContext';
import {
  generateDefaultCategoryValues,
  generateDefaultModelsValues,
  generateDefaultThemeValues,
} from './formUtils';

type FormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  onSubmit: SubmitHandler<MultimetricSelectedFilters>;
};

export const MultimetricForm = ({ children, onSubmit }: FormProps) => {
  const { state } = useContext(AppContext);
  const methods = useForm<MultimetricSelectedFilters>({
    defaultValues: {
      modelPicker: generateDefaultModelsValues(state.application.models),
      themesPicker: generateDefaultThemeValues(state.application.themes),
      categoriesPicker: generateDefaultCategoryValues(state.application.categories),
    },
  });

  useEffect(() => {
    const updatedDefaultModelsValues = generateDefaultModelsValues(state.application.models);
    const updatedDefaultCategoriesValues = generateDefaultCategoryValues(
      state.application.categories
    );
    const updatedDefaultThemesValues = generateDefaultThemeValues(state.application.themes);

    methods.reset({
      ...methods.getValues(),
      modelPicker: updatedDefaultModelsValues,
      categoriesPicker: updatedDefaultCategoriesValues,
      themesPicker: updatedDefaultThemesValues,
    });
  }, [state.application.categories, methods, state.application.themes, state.application.models]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default MultimetricForm;
